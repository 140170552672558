import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import { Modal } from "antd";

import { formText } from "langs/formatText";
import PrimaryButton from "components/Buttons/PrimaryButton";
import ActionButton from "components/Buttons/ActionButton";
import * as S from "./index.style";

const buttonStyle = { width: 80, height: 32, margin: "0 12px" };

interface ConfirmModalProps {
  title?: string;
  tip?: string;
  cancel?: boolean;
  cancelText?: string;
  confirmText?: string;
  onClose?: (comfirm: boolean) => void;
  onConfirm?: () => void;
  visible: boolean;
  width?: number;
  style?: React.CSSProperties;
  btnStyle?: React.CSSProperties;
  confirmLoading?: boolean;
}

const ConfirmModal = (props: ConfirmModalProps) => {
  const preventPropagation = useCallback((e: React.MouseEvent) => {
    if (e) e.stopPropagation();
  }, []);

  return (
    <Modal
      footer={null}
      visible={props.visible}
      width={props.width || 320}
      closable={false}
      centered={true}
      bodyStyle={{ padding: 0 }}
    >
      <S.Wrapper style={props.style} onClick={preventPropagation}>
        {!!props.title && <S.Title>{props.title}</S.Title>}
        {!!props.tip && <S.Tip>{props.tip}</S.Tip>}
        <S.Actions>
          {props.cancel !== false && (
            <ActionButton
              style={
                props.btnStyle
                  ? { ...buttonStyle, ...props.btnStyle }
                  : buttonStyle
              }
              onClick={() => {
                props.onClose && props.onClose(false);
              }}
            >
              {props.cancelText || formText.CancelText}
            </ActionButton>
          )}
          <PrimaryButton
            style={
              props.btnStyle
                ? { ...buttonStyle, ...props.btnStyle }
                : buttonStyle
            }
            onClick={() => {
              props.onConfirm && props.onConfirm();
              !props.onConfirm && props.onClose && props.onClose(true);
            }}
            loading={props.confirmLoading}
          >
            {props.confirmText || formText.OKText}
          </PrimaryButton>
        </S.Actions>
      </S.Wrapper>
    </Modal>
  );
};

interface ShowConfirmModalProps {
  title?: string;
  tip?: string;
  cancel?: boolean;
  cancelText?: string;
  confirmText?: string;
  onClose?: (comfirm: boolean) => void;
  onConfirm?: () => void;
  width?: number;
  style?: React.CSSProperties;
  btnStyle?: React.CSSProperties;
}
function showConfirmModal(props: ShowConfirmModalProps) {
  const { onClose, onConfirm, ...rest } = props;
  const div = document.createElement("div");
  document.body.appendChild(div);
  ReactDOM.render(
    <ConfirmModal
      {...rest}
      visible
      onConfirm={() => {
        onConfirm ? onConfirm() : props.onClose && props.onClose(true);
        ReactDOM.unmountComponentAtNode(div);
        document.body.removeChild(div);
      }}
      onClose={(f) => {
        props.onClose && props.onClose(f);
        ReactDOM.unmountComponentAtNode(div);
        document.body.removeChild(div);
      }}
    />,
    div
  );
}

export default ConfirmModal;
export { showConfirmModal };
