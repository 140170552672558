import useSWR from "swr";
import request from "../request";
import { TimeUnit } from "utils/date";
import { getTokenValue } from "../request/accessToken";

export const getScannerLocations = async (
  _url: string,
  page: number,
  size: number,
  spaceId: string,
  type: string,
  scannerId: string
) =>
  request<ScannerLocationsResp>({
    path: "/admin/v1/product/units",
    method: "GET",
    data: { page, size, spaceId, type, scannerId },
    refreshOnUnauthorized: true,
  });

export function useScannersLocationLoader(
  page: number,
  size: number,
  locations?: string,
  types?: string,
  scanner?: string
) {
  const accessToken = getTokenValue();
  const { data, mutate, isValidating, error } = useSWR(
    [`${accessToken}/scanner-locations`, page, size, locations, types, scanner],
    getScannerLocations,
    { refreshInterval: TimeUnit.Minute * 5, shouldRetryOnError: false }
  );
  let result: ScannerLocations[] = [];
  let total = 0;
  if (data) {
    if (data.total) total = data.total;
    if (data.items) {
      result = data.items.map((v) => ({
        ...v,
        lastModifiedTime: new Date(v.lastModifiedTime).getTime(),
      }));
    }
  }
  return { data: result.slice(0, size), mutate, isValidating, total, error };
}

export const getScanners = async (
  _url: string,
  page: number,
  size: number,
  keyword?: string,
  spaceId?: string
) =>
  request<IScannersResp>({
    path: "/admin/v1/scanners",
    method: "GET",
    data: { page, size, keyword, spaceId },
    refreshOnUnauthorized: true,
  });

export const addScanner = async (data: IScannerFormItem) =>
  request<IScannersResp>({
    path: "/admin/v1/scanners",
    method: "POST",
    data,
    refreshOnUnauthorized: true,
  });

export const editScanner = async (data: IScannerFormEditItem) =>
  request<IScannersResp>({
    path: "/admin/v1/scanners",
    method: "PUT",
    data,
    refreshOnUnauthorized: true,
  });

export const deleteScanner = async (id: string | number) =>
  request<IScannersResp>({
    path: `/admin/v1/scanners/${id}`,
    method: "DELETE",
    refreshOnUnauthorized: true,
  });

export const getScanner = async (id: string | number) =>
  request<IScanner>({
    path: `/admin/v1/scanners/${id}`,
    method: "GET",
    refreshOnUnauthorized: true,
  });

export const getScannerVersion = async () =>
  request<Array<{ version: string; name: string }>>({
    path: "/admin/v1/scanners/version",
    method: "GET",
    refreshOnUnauthorized: true,
  });

export const getConfigScanners = async (
  _url: string,
  page: number,
  size: number,
  keyword?: string,
  spaceId?: string
) =>
  request<IScannersResp>({
    path: "/admin/v1/scanners",
    method: "GET",
    data: { configured: "false", page, size, keyword, spaceId },
    refreshOnUnauthorized: true,
  });

export function useScannersLoader(
  page: number,
  size: number,
  revalidateOnFocus?: boolean,
  keyword?: string,
  spaceId?: string
) {
  const accessToken = getTokenValue();
  const { data, mutate, isValidating, error } = useSWR(
    [`${accessToken}/scanners`, page, size, keyword, spaceId],
    getScanners,
    {
      refreshInterval: TimeUnit.Minute * 5,
      revalidateOnFocus: !!revalidateOnFocus,
    }
  );
  let result: IScanner[] = [];
  let total = 0;
  if (data) {
    if (data.total) total = data.total;
    if (data.items) {
      result = [...data.items];
    }
  }
  return {
    data: result.slice(0, size),
    mutate,
    isValidating,
    total,
    loadPage: page,
    error,
  };
}

export const getInventories = async (
  _url: string,
  page: number,
  size: number,
  type?: string,
  spaceId?: string
) =>
  request<IScannersResp>({
    path: "/admin/v1/scanners/inventory/list",
    method: "GET",
    data: { page, size, type, spaceId },
    refreshOnUnauthorized: true,
  });

export function useScannerInventoriesLoader(
  page: number,
  size: number,
  type?: string,
  spaceId?: string
) {
  const accessToken = getTokenValue();
  const { data, mutate, isValidating, error } = useSWR(
    [
      `${accessToken}/scanners/inventory/list`,
      page,
      size,
      type,
      spaceId || undefined,
    ],
    getInventories,
    { refreshInterval: 0, revalidateOnFocus: false }
  );
  let result: any[] = [];
  let total = 0;
  if (data) {
    if (data.total) total = data.total;
    if (data.items) {
      result = [...data.items];
    }
  }
  return {
    data: result.slice(0, size),
    mutate,
    isValidating,
    total,
    loadPage: page,
    error,
  };
}

export const bindScanner = async (unitId: string, scannerId: string) =>
  request({
    path: "/admin/v1/product/bound-scanner",
    method: "POST",
    data: { unitId, scannerId },
    refreshOnUnauthorized: true,
  });

export const confirmScannerConfig = async (id: string) =>
  request<IScanner>({
    path: `/admin/v1/scanners/${id}`,
    method: "PUT",
    refreshOnUnauthorized: true,
  });

export const getAllSpaces = async (city?: string) =>
  request<IAllSpaceResp>({
    path: "/space-partner/v1/spaces",
    method: "GET",
    refreshOnUnauthorized: true,
    data: { city },
  });
export const getAllPublishedSpaces = async (city?: string) =>
  request<IAllSpaceResp>({
    path: "/space-partner/v1/published/spaces",
    method: "GET",
    refreshOnUnauthorized: true,
    data: { city },
  });

export const getMaintenceQR = async () =>
  request<{ code: string }>({
    path: "/admin/v1/qrcode/maintenance",
    method: "GET",
    data: { type: "UNLOCK_DOOR" },
    refreshOnUnauthorized: true,
  });
