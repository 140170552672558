import { useState, useCallback } from "react";
import { createContainer } from "unstated-next";

const container = createContainer(() => {
  const [height, setHeight] = useState<number | null>(null);
  const onUpdateHeight = useCallback((v) => setHeight(v), []);
  return { height: height || undefined, setHeight: onUpdateHeight };
});

export const PortalStyleProvider = container.Provider;
export const usePortalStyle = container.useContainer;
export default container;
