import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

import * as S from "./style.PortalLayout";

import { checkHistoryLeave } from "components/HistoryBlock";
import { logout } from "service/v1/identity";
import useManualFetcher from "hooks/useManualFetcher";
import useButtonPressed from "hooks/useButtonPressedEffect";
import { useAccessToken } from "store/accessToken";
// import GeneralError from "service/request/GeneralError";
import { formText } from "langs/formatText";

export function useLogout() {
  const history = useHistory();
  const { doFetch } = useManualFetcher(logout);
  const { clearToken } = useAccessToken();
  const doLogout = React.useCallback(() => {
    checkHistoryLeave(() => {
      doFetch()
        .then(() => {
          clearToken();
          history.replace("/");
        })
        .catch((e) => {
          clearToken();
          history.replace("/");
          // if (e instanceof GeneralError)
          //   message.error(e.message || messageText.UnknownErrorText);
        });
    });
  }, [doFetch, clearToken, history]);
  return { doLogout };
}

export const SignOutButton = React.memo(() => {
  const { doLogout } = useLogout();
  const { onMouseDown, onMouseUp } = useButtonPressed();
  return (
    <S.LogoutButton
      // icon={<MinusCircleOutlined />}
      onClick={doLogout}
      style={{ height: S.HeaderHeight, marginRight: "32px" }}
      onMouseDownCapture={onMouseDown}
      onMouseUpCapture={onMouseUp}
      onMouseOutCapture={onMouseUp}
    >
      {formText.LogOutText}
    </S.LogoutButton>
  );
});

export const ItemSignOuButton = React.memo(() => {
  const { doLogout } = useLogout();
  return (
    <Button type="text" style={{ width: "100%" }} onClick={doLogout}>
      {formText.LogOutText}
    </Button>
  );
});
