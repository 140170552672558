import React, { useCallback, forwardRef } from "react";
import { LoadingOutlined } from "@ant-design/icons";

import useButtonPressed from "hooks/useButtonPressedEffect";

import { SubtitleText } from "styles/fonts";
import * as S from "./styles.button";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  loading?: boolean;
  action?: () => void;
  onClick?: () => void;
  disabled?: boolean;
  icon?: JSX.Element;
  text?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  isPrimary?: boolean;
  defaultBgColor?: string;
}

const ActionButton = React.memo(
  forwardRef<HTMLDivElement, ButtonProps>(
    (
      { loading, action, disabled, onClick, icon, text, children, ...rest },
      ref
    ) => {
      const { pressed, onMouseDown, onMouseUp } = useButtonPressed();
      const onClickFunc = useCallback(() => {
        if (loading || disabled) return null;
        if (action) action();
        if (onClick) onClick();
      }, [action, loading, disabled, onClick]);
      return (
        <S.ButtonWrapper
          {...rest}
          ref={ref}
          onClick={onClickFunc}
          onMouseDownCapture={onMouseDown}
          onMouseUpCapture={onMouseUp}
          onMouseOutCapture={onMouseUp}
          pressed={pressed}
          disabled={loading || disabled}
        >
          {loading && (
            <LoadingOutlined
              style={{ width: 16, height: 16, marginRight: 5 }}
            />
          )}
          {!loading && !!icon && icon}
          <SubtitleText>{text || children}</SubtitleText>
        </S.ButtonWrapper>
      );
    }
  )
);

export default ActionButton;
