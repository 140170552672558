import React from "react";
import { Dropdown as ADropdown, DropDownProps as ADropdownProps } from "antd";
import * as S from "./index.style";

export type DropdownProps = Omit<ADropdownProps, "overlay"> & {
  children: React.ReactNode;
  content: (() => React.ReactNode) | React.ReactNode;
};
export default function Dropdown(props: DropdownProps) {
  const { content, children, ...rest } = props;
  return (
    <ADropdown
      overlay={<S.DropdownOverlay>{content}</S.DropdownOverlay>}
      {...rest}
    >
      {children}
    </ADropdown>
  );
}
