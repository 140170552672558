import { createGlobalStyle, css } from "styled-components";
import { Roboto } from "styles/fonts";
import { Primary, Success1, Error1, Link1, Warning1 } from "styles/colors";
import { LeftSiderWidth } from "components/PortalLayout/style.PortalLayout";
import { testMobile } from "utils/os";

const isMobile = testMobile();

const GlobalStyle = createGlobalStyle`
  h3 {
    margin: 0;
    padding: 0;
  }

  p {
    margin-bottom: 0;
    margin-top: 0;
  }

  .admin-left-slider .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .portal-notification {
    .ant-message-notice-content {
      padding: 11px 18px;
      max-width: 60vw;
    }

    &.success {
      .ant-message-notice-content {
        background-color: ${Success1};
      }
    }

    &.error {
      .ant-message-notice-content {
        background-color: ${Error1};
      }
    }

    &.warn {
      .ant-message-notice-content {
        background-color: ${Warning1};
      }
    }

    &.info {
      .ant-message-notice-content {
        background-color: ${Link1};
      }
    }

    .ant-notification-notice-message {
      margin-bottom: 0;
      padding-right: 0 !important;
    }

    .ant-notification-notice-close {
      top: 8px;
      right: 32px;
    }
  }

  html {
    --antd-wave-shadow-color: ${Primary};

    ${Roboto}
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
  }

  [class^="ant-"] {
    ${Roboto}
  }

  /* fix baidu map autocomplete dropdown */
  .tangram-suggestion-main {
    z-index: 10;
  }

  .commonMessage {
    ${
      !isMobile
        ? css`
            margin-left: ${LeftSiderWidth}px;
          `
        : ""
    }
  }
  
`;

export default GlobalStyle;
