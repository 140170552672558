import intl from "langs/intl";

export const OverviewText = intl.formatMessage({ defaultMessage: "Overview" });
export const OtherInformationText = intl.formatMessage({
  defaultMessage: "Other Information",
});
export const SpacesText = intl.formatMessage({ defaultMessage: "Locations" });
export const SpaceConfigurationText = intl.formatMessage({
  defaultMessage: "Space Configuration",
});
export const WalkInsText = intl.formatMessage({ defaultMessage: "Walk-ins" });
export const WalkInsUsageText = intl.formatMessage({
  defaultMessage: "Walk-in Usage",
});
export const UsageText = intl.formatMessage({ defaultMessage: "Usage" });
export const BookingsText = intl.formatMessage({ defaultMessage: "Bookings" });
export const BookingUsageText = intl.formatMessage({
  defaultMessage: "Booking Usage",
});
export const ManageBookingsText = intl.formatMessage({
  defaultMessage: "Manage Bookings",
});
export const ScannerMappingText = intl.formatMessage({
  defaultMessage: "Scanner Mapping",
});
export const ScannerManagementText = intl.formatMessage({
  defaultMessage: "Scanner Management",
});
export const ScannerConfigText = intl.formatMessage({
  defaultMessage: "Scanner Config",
});
export const ScannerQRText = intl.formatMessage({
  defaultMessage: "Scanner QR",
});
export const MaintenanceQRText = intl.formatMessage({
  defaultMessage: "Maintenance QR",
});
export const AppOpenText = intl.formatMessage({
  defaultMessage: "App Open",
});
export const SPManagementText = intl.formatMessage({
  defaultMessage: "SP Management",
});
export const RoleManagementText = intl.formatMessage({
  defaultMessage: "Role Management",
});
export const AdminManagementText = intl.formatMessage({
  defaultMessage: "User Management",
});

export const UserRatingText = intl.formatMessage({
  defaultMessage: "User Rating",
});
