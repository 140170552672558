import {
  dateText,
  formText,
  navigatorText,
  productText,
} from "langs/formatText";
import intl from "langs/intl";

export enum ProductType {
  ALL = "",
  POD = "POD",
  COWORKING = "COWORKING",
  DESK = "DESK",
}

export enum SpaceRouteType {
  OVERVIEW = "Overview",
  INVENTORY = "Inventory",
  OTHER = "Other",
}

export const SpaceRouteTypeLabels = {
  [SpaceRouteType.OVERVIEW]: navigatorText.OverviewText,
  [SpaceRouteType.INVENTORY]: productText.InventoryText,
  [SpaceRouteType.OTHER]: navigatorText.OtherInformationText,
};

export enum UnitType {
  MINUTE = "Minute",
  HOUR = "Hour",
}

export const UnitTypeText: { [key: string]: string } = {
  [UnitType.MINUTE]: dateText.MinuteText,
  [UnitType.HOUR]: dateText.HourText,
};

export enum SortType {
  NONE = "",
  ASC = "asc",
  DESC = "desc",
}

export enum InventoryType {
  DESK = "DESK",
  BOOTH = "POD",
  MEETING_ROOM = "MEETING_ROOM",
  PRIVATE_OFFICE = "PRIVATE_OFFICE",
  EVENT_SPACE = "EVENT_SPACE",
  KEY_CARD_TYPE = "KEY_CARD_TYPE",
}

export enum DeskSubType {
  HOTDESK = "HOT_DESK",
  DEDICATED_DESK = "DEDICATED_DESK",
}

export enum PodSubType {
  PAX_1 = "1PAX",
  PAX_4 = "4PAX",
}

export enum BoothType {
  INSTANT_BOOKING = "INSTANT_BOOKING",
  WALKIN = "WALK_IN",
}

export const InventoryTypesArr = [
  InventoryType.BOOTH,
  InventoryType.DESK,
  InventoryType.MEETING_ROOM,
  InventoryType.PRIVATE_OFFICE,
  InventoryType.EVENT_SPACE,
];

export const InventoryTypeName = {
  [InventoryType.DESK]: productText.DeskText,
  [InventoryType.BOOTH]: productText.BoothText,
  [InventoryType.MEETING_ROOM]: productText.MeetingRoomText,
  [InventoryType.PRIVATE_OFFICE]: productText.PrivateOfficeText,
  [InventoryType.EVENT_SPACE]: productText.EventSpaceText,
  [InventoryType.KEY_CARD_TYPE]: productText.keyCardTypeText,
};
export const ProductTypeName = {
  [InventoryType.DESK]: productText.CoworkingText,
  COWORKING: productText.CoworkingText,
  [InventoryType.BOOTH]: productText.BoothText,
  [InventoryType.MEETING_ROOM]: productText.MeetingRoomText,
  [InventoryType.PRIVATE_OFFICE]: productText.PrivateOfficeText,
  [InventoryType.EVENT_SPACE]: productText.EventSpaceText,
};

export const InventorySubTypeName = {
  [DeskSubType.HOTDESK]: productText.HotDeskText,
  [DeskSubType.DEDICATED_DESK]: productText.DedicatedDeskText,
  [PodSubType.PAX_1]: productText.SeatBoothText(1),
  [PodSubType.PAX_4]: productText.SeatBoothText(4),
};

export enum SpacePublishType {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

export const SpacePublishText = {
  [SpacePublishType.PUBLISHED]: productText.PublishedText,
  [SpacePublishType.UNPUBLISHED]: productText.UnpublishedText,
};

export enum SpaceStatusType {
  OPEN = "OPEN",
  CLOSE = "CLOSED",
}

export const SpaceStatusText = {
  [SpaceStatusType.OPEN]: productText.OpenText,
  [SpaceStatusType.CLOSE]: formText.SpaceClosedText,
};

export enum InventoryPriceType {
  WALK_IN = "WALK_IN",
  BOOKING = "BOOKING",
}
export enum InventoryPriceSubType {
  FLAT = "FLAT",
  SLIDING = "SLIDING",
}

export const InventoryPriceSubTypeText: Record<
  InventoryPriceSubType,
  string
> = {
  [InventoryPriceSubType.FLAT]: intl.formatMessage({
    defaultMessage: "Flat Pricing",
  }),
  [InventoryPriceSubType.SLIDING]: intl.formatMessage({
    defaultMessage: "Sliding Price",
  }),
};

export const InventoryPriceSubTypeOptions = [
  {
    key: InventoryPriceSubType.FLAT,
    text: InventoryPriceSubTypeText[InventoryPriceSubType.FLAT],
    tooltip: intl.formatMessage({
      defaultMessage:
        "Flat Pricing means that unit price of user’s stay will not vary across stay time.",
    }),
  },
  {
    key: InventoryPriceSubType.SLIDING,
    text: InventoryPriceSubTypeText[InventoryPriceSubType.SLIDING],
    tooltip: intl.formatMessage({
      defaultMessage:
        "Sliding Pricing means the pricing per unit of time will decrease as the stay time increases.",
    }),
  },
];

export enum InventoryPricingUnit {
  PT1M = "PT1M",
}

export enum CapacityType {
  ONE_SEAT = "ONE_SEAT",
  TWO_TO_FOUR_SEAT = "TWO_TO_FOUR_SEAT",
  FIVE_TO_NINE_SEAT = "FIVE_TO_NINE_SEAT",
  TEN_TO_FIFTEEN_SEAT = "TEN_TO_FIFTEEN_SEAT",
  SIXTEEN_TO_TWENTY_SEAT = "SIXTEEN_TO_TWENTY_SEAT",
  OVER_TWENTY_SEAT = "OVER_TWENTY_SEAT",
}

export const CapacityTypeText = {
  [CapacityType.ONE_SEAT]: productText.CapacityTypeText(1, 0),
  [CapacityType.TWO_TO_FOUR_SEAT]: productText.CapacityTypeText(2, 4),
  [CapacityType.FIVE_TO_NINE_SEAT]: productText.CapacityTypeText(5, 9),
  [CapacityType.TEN_TO_FIFTEEN_SEAT]: productText.CapacityTypeText(10, 15),
  [CapacityType.SIXTEEN_TO_TWENTY_SEAT]: productText.CapacityTypeText(16, 20),
  [CapacityType.OVER_TWENTY_SEAT]: productText.CapacityTypeText(20, -1),
};

export const CapacityTypeValue: {
  [keys: string]: { gte?: number; lte?: number };
} = {
  [CapacityType.ONE_SEAT]: { gte: 1, lte: 1 },
  [CapacityType.TWO_TO_FOUR_SEAT]: { gte: 2, lte: 4 },
  [CapacityType.FIVE_TO_NINE_SEAT]: { gte: 5, lte: 9 },
  [CapacityType.TEN_TO_FIFTEEN_SEAT]: { gte: 10, lte: 15 },
  [CapacityType.SIXTEEN_TO_TWENTY_SEAT]: { gte: 16, lte: 20 },
  [CapacityType.OVER_TWENTY_SEAT]: { gte: 20 },
};

export const MaxDesLength = 700;
export const MaxContent = 100;
export const MaxNameLength = 50;

export enum OrderSource {
  APP = "SWITCH_APP",
  PORTAL = "SPACE_PROVIDER_PORTAL",
}

export const BookingOrderSourceLabel = {
  [OrderSource.APP]: productText.SwitchAppText,
  [OrderSource.PORTAL]: productText.SwitchPortalText,
};

export enum ScannerVersion {
  MC_100 = "MC_100",
  MP_86_CUSTOMISED = "MP_86_CUSTOMISED",
  MP_86 = "MP_86",
  MX_86 = "MX_86",
}

export const ScannerVersionOptions = [
  ScannerVersion.MC_100,
  ScannerVersion.MP_86_CUSTOMISED,
  ScannerVersion.MP_86,
  ScannerVersion.MX_86,
];

export const ScannerVersionLabel = {
  [ScannerVersion.MC_100]: productText.WiFiVersionText,
  [ScannerVersion.MP_86_CUSTOMISED]: productText.CableVersionText,
  [ScannerVersion.MP_86]: productText.BasicCableScannerText,
  [ScannerVersion.MX_86]: productText.BasicScannerText,
};

export enum SpaceCodeUsageType {
  PUBLIC = "PUBLIC_CODE",
  PRIVATE = "PRIVATE_CODE",
}

export enum BookingType {
  INSTANT_BOOKING = "INSTANT_BOOKING",
  CONFIRM_BOOKING = "CONFIRM_BOOKING",
}

export const BookingTypeOptionLabel = {
  [BookingType.INSTANT_BOOKING]: productText.InstantBookingText,
  [BookingType.CONFIRM_BOOKING]: productText.ConfirmBookingOptinText,
};

export const UsageTypeOptionLabel = {
  WALK_IN: productText.WalkInText,
  "Instant Confirm": productText.InstantBookingText,
  "Non-instant Confirm": productText.ConfirmBookingOptinText,
};

export const BookingTypeOptions = [
  {
    key: BookingType.INSTANT_BOOKING,
    text: BookingTypeOptionLabel[BookingType.INSTANT_BOOKING],
  },
  {
    key: BookingType.CONFIRM_BOOKING,
    text: BookingTypeOptionLabel[BookingType.CONFIRM_BOOKING],
  },
];
export enum SpaceUsageType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export const BoothTypeOptions = [
  {
    key: BoothType.WALKIN,
    text: productText.WalkInText,
  },
  {
    key: BoothType.INSTANT_BOOKING,
    text: productText.InstantBookingText,
  },
];

export const SpaceUsageTypeLabel = {
  [SpaceUsageType.PUBLIC]: intl.formatMessage({
    defaultMessage: "Public",
  }),
  [SpaceUsageType.PRIVATE]: intl.formatMessage({
    defaultMessage: "Private",
  }),
};

export enum SpacePartnerAccessType {
  NORMAL = "NORMAL",
  METRICS_ONLY = "METRICS_ONLY",
}

export const SpacePartnerAccessTypeLabel = {
  [SpacePartnerAccessType.NORMAL]: intl.formatMessage({
    defaultMessage: "Normal",
  }),
  [SpacePartnerAccessType.METRICS_ONLY]: intl.formatMessage({
    defaultMessage: "Metrics Only",
  }),
};

export enum BoothWifiType {
  RUCKUS = "Ruckus",
  SUNDRAY = "Sundray",
}

export enum KeyCardType {
  SALTO = "SALTO",
}
