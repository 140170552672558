import request from "../request";

export const getAccountInventoryType = async () =>
  request<TAccountInventoryTypeResp>({
    path: "/space-partner/v1/performances/types",
    method: "GET",
    refreshOnUnauthorized: true,
  });

export const getAccountBookingType = async () =>
  request<TAccountInventoryTypeResp>({
    path: "/admin/v1/inventories/bookable-types",
    method: "GET",
    refreshOnUnauthorized: true,
  });

export const getContactInventoryList = async (
  spaceId: string,
  inventoryType: InventoryType
) =>
  request<TContactInventoryListResp>({
    path: `/admin/v1/inventories/getOpenInventoryList?spaceId=${spaceId}&inventoryType=${inventoryType}`,
    method: "POST",
    refreshOnUnauthorized: true,
    data: {
      spaceId,
      inventoryType,
    },
  });
