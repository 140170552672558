import styled, { css } from "styled-components";
import { FlexRow } from "styles/common";
import { Success, Link, Error, Neutral11, Warning } from "styles/colors";
import { Body2CSS } from "styles/fonts";
import { ReactComponent as InfoSVG } from "images/icon-info.svg";
import { ReactComponent as SuccessSVG } from "images/icon-success.svg";
import { ReactComponent as WarnSVG } from "images/icon-warn.svg";
import { ReactComponent as CloseSVG } from "images/icon-close.svg";

const IconCSS = css`
  width: 20px;
  height: 20px;
  flex: none;
  margin-right: 10px;
`;

export const IconSuccess = styled(SuccessSVG)`
  fill: ${Success};
  ${IconCSS}
`;

export const IconError = styled(WarnSVG)`
  fill: ${Error};
  ${IconCSS}
`;
export const IconWarn = styled(InfoSVG)`
  fill: ${Warning};
  ${IconCSS}
`;
export const IconInfo = styled(InfoSVG)`
  fill: ${Link};
  ${IconCSS}
`;
export const IconClose = styled(CloseSVG)`
  fill: ${Neutral11};
  width: 20px;
  height: 20px;
  flex: none;
`;

export const Wrapper = styled.div`
  ${FlexRow}
`;

export const Message = styled.p`
  ${Body2CSS}
  margin: 0;
  line-height: 20px;
  text-align: left;
`;
