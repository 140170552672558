import React, { useCallback, forwardRef } from "react";
import { LoadingOutlined } from "@ant-design/icons";

import useButtonPressed from "hooks/useButtonPressedEffect";

import * as S from "./styles.button";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  action?: (e?: React.MouseEvent) => void;
  onClick?: (e?: React.MouseEvent) => void;
  icon?: JSX.Element;
  text?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  isSecondary?: boolean;
  defaultBgColor?: string;
}

const PrimaryButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      loading,
      action,
      disabled,
      onClick,
      icon,
      text,
      children,
      isSecondary,
      ...rest
    },
    ref
  ) => {
    const { pressed, onMouseDown, onMouseUp } = useButtonPressed();
    const onClickFunc = useCallback(
      (e: React.MouseEvent) => {
        if (loading || disabled) return null;
        if (action) action();
        if (onClick) onClick(e);
      },
      [action, loading, disabled, onClick]
    );
    const _onMouseUp = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        if (loading || disabled) return null;
        onMouseUp();
        if (rest.onMouseUp) rest.onMouseUp(e);
      },
      [disabled, loading, rest, onMouseUp]
    );
    const _onMouseDown = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        if (loading || disabled) return null;
        onMouseDown();
        if (rest.onMouseDown) rest.onMouseDown(e);
      },
      [disabled, loading, rest, onMouseDown]
    );
    const _onMouseOut = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        if (loading || disabled) return null;
        onMouseUp();
        if (rest.onMouseOut) rest.onMouseOut(e);
      },
      [disabled, loading, rest, onMouseUp]
    );
    return (
      <S.ButtonWrapper
        {...rest}
        ref={ref}
        onClick={onClickFunc}
        onMouseDown={_onMouseDown}
        onMouseUp={_onMouseUp}
        onMouseOut={_onMouseOut}
        pressed={pressed}
        disabled={loading || disabled}
        secondary={isSecondary}
      >
        {loading && (
          <LoadingOutlined style={{ width: 16, height: 16, marginRight: 5 }} />
        )}
        {!loading && !!icon && icon}
        {text || children}
      </S.ButtonWrapper>
    );
  }
);

export default React.memo(PrimaryButton);
