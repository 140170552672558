import moment from "moment-timezone";

export const defaultTimeZone = "Asia/Singapore";
export const defaultUTCOffset = "+08:00";
export const defaultTimeZoneAbbr = "SGT";

export const TimeUnit = {
  Second: 1000,
  Minute: 60 * 1000,
  Hour: 60 * 60000,
  Day: 24 * 3600000,
};

type DateValue = number | string;

// 2020-01-22T10:00:00Z  1579687200000
export function getTimeStr(date: DateValue, timeZone?: string) {
  if (timeZone) {
    const localUTC = new Date(date).getTime();
    const targetUTC = new Date(
      new Date(date).toLocaleString("en-US", { timeZone })
    ).getTime();
    const diff = targetUTC - localUTC;
    const resetUTC = localUTC - diff;
    return new Date(resetUTC).toISOString().split(".")[0] + "Z";
  }
  return new Date(date).toISOString().split(".")[0] + "Z";
}

export function getTimezoneDiff(timeZone?: string) {
  if (!timeZone) return 0;
  const cur = Date.now();
  const localUTC = new Date(cur).getTime();
  const targetUTC = new Date(
    new Date(cur).toLocaleString("en-US", { timeZone })
  ).getTime();
  return targetUTC - localUTC;
}

export function formatTimezoneString(
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number,
  timeZone?: string
) {
  return getTimeStr(`${year}/${month}/${day} ${hour}:${minute}`, timeZone);
}

export function getDateInfo(date: Date) {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return { month, day, year, hours, minutes, seconds };
}

export function startOfWeek(date: Date) {
  const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  const { year, month, day } = getDateInfo(new Date(date.setDate(diff)));
  return new Date(`${year}/${month}/${day} 0:0:0`).getTime();
}

export function endOfWeek(date: Date) {
  const weekEnd = new Date(
    date.setDate(date.getDate() - (date.getDay() - 1) + 6)
  );
  const { year, month, day } = getDateInfo(weekEnd);
  return new Date(`${year}/${month}/${day} 23:59:59`).getTime();
}

export function startOfMonth(year: number, month: number) {
  return new Date(`${year}/${month}/1 0:0:0`).getTime();
}

export function endOfMonth(year: number, month: number) {
  return new Date(year, month, 0, 23, 59, 59).getTime();
}

export function getDateRange(date: Date) {
  const { year, month, day } = getDateInfo(date);
  const dayBeforeInfo = getDateInfo(new Date(date.getTime() - 86400000));
  const todayStart = new Date(`${year}/${month}/${day} 0:0:0`).getTime();
  const todayEnd = new Date(`${year}/${month}/${day} 23:59:59`).getTime();
  const dayBeforeStart = new Date(
    `${dayBeforeInfo.year}/${dayBeforeInfo.month}/${dayBeforeInfo.day} 0:0:0`
  ).getTime();
  const dayBeforeEnd = new Date(
    `${dayBeforeInfo.year}/${dayBeforeInfo.month}/${dayBeforeInfo.day} 23:59:59`
  ).getTime();
  const yearStart = new Date(`${year}/1/1 0:0:0`).getTime();
  const yearEnd = new Date(`${year + 1}/1/1 0:0:0`).getTime() - TimeUnit.Second;
  const weekStart = startOfWeek(date);
  const weekEnd = endOfWeek(date);
  const monthStart = startOfMonth(year, month);
  const monthEnd = endOfMonth(year, month);
  return {
    todayStart,
    todayEnd,
    dayBeforeStart,
    dayBeforeEnd,
    weekStart,
    weekEnd,
    monthStart,
    monthEnd,
    yearStart,
    yearEnd,
  };
}

export function formatFormDate(date?: number | string, isTwelve?: boolean) {
  // Sep 8, 2020, 13:14
  if (!date) return "";
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("en", { month: "short" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  let suffix = "am";
  if (isTwelve && hours >= 12) {
    suffix = "pm";
    // if (hours > 12) {
    //   // hours = hours - 12;
    //   suffix = "pm";
    // }
    // if (hours === 12 && minutes > 0) {
    //   suffix = "pm";
    // }
  }
  const result = `${month} ${day}, ${year}, ${formatNumWithBit(
    hours
  )}:${formatNumWithBit(minutes)}`;
  if (isTwelve) return `${result} ${suffix}`;
  return result;
}

export function formatColumnDate(
  date?: number | string,
  withoutHours?: boolean,
  type?: string | "withoutSeconds" | "weekDay"
) {
  // Sep 8, 2020, 13:14:12
  if (!date) return "";
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("en", { month: "short" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  let hours = formatNumWithBit(dateObj.getHours());
  const minutes = formatNumWithBit(dateObj.getMinutes());
  const seconds = formatNumWithBit(dateObj.getSeconds());
  if (withoutHours) return `${month} ${day}, ${year}`;
  if (type === "withoutSeconds")
    return `${month} ${day}, ${year}, ${hours}:${minutes}`;
  if (type === "weekDay") {
    const weekDay = dateObj.toLocaleDateString("en", { weekday: "short" });
    return `${weekDay}, ${month} ${day}, ${year}, ${hours}:${minutes}`;
  }
  const result = `${month} ${day}, ${year}, ${hours}:${minutes}:${seconds}`;
  return result;
}

export function formatNumWithBit(value: number) {
  if (value >= 10) return value;
  return `0${Math.abs(value)}`;
}

export function getMSByTimezone(timeZone: string, time?: string | number) {
  // const utcOffset = parseInt(timeZone.toUpperCase().replace("UTC", ""), 10);
  return moment(time).clone().tz(timeZone);
}

export function getMillisecondsByTimezone(
  timeZone: string,
  time?: string | number,
  withoutSec?: boolean,
  divideSymbol: string = "/"
) {
  const date = moment(time).clone().tz(timeZone);
  const year = date.year();
  const month = formatNumWithBit(date.month() + 1);
  const day = formatNumWithBit(date.date());
  const hours = formatNumWithBit(date.hours());
  const minutes = formatNumWithBit(date.minutes());
  const sec = withoutSec ? "00" : formatNumWithBit(date.seconds());
  const result = new Date(
    `${year}${divideSymbol}${month}${divideSymbol}${day} ${hours}:${minutes}:${sec}`
  ).getTime();
  return result;
}
/** @description MMM DD, YYYY, hh:mm (abbr) */
export function getDateByTimezoneWithAbbr(
  timeZone: string,
  time?: string | number,
  timeZoneAbbreviation?: string,
  noAbbr?: boolean
) {
  if (!time) return "";
  const date = moment(time).clone().tz(timeZone);
  const hours = formatNumWithBit(date.hours());
  const minutes = formatNumWithBit(date.minutes());
  const str = `${date.clone().format("MMM DD, YYYY")}, ${hours}:${minutes}`;
  if (noAbbr || !timeZone) return str;
  if (timeZoneAbbreviation) return `${str} (${timeZoneAbbreviation})`;
  let abbr = moment.tz(timeZone).zoneAbbr();
  if (Number.isSafeInteger(parseInt(abbr, 10))) abbr = `UTC${abbr}`;
  return `${str} (${abbr})`;
}

export const formatSubmitDateStr = (time: number | string) => {
  const day = new Date(time);
  const m = day.getMonth() + 1;
  const d = day.getDate();
  return `${day.getFullYear()}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d}`;
};

export const formatDateStrWithTimezone = (
  time: number | string,
  timeZone: string
) => {
  return new Date(time).toLocaleString("en-US", {
    timeZone,
  });
};

export function formatCalendarSelectDate(date: Date) {
  const monthName = date.toLocaleString("en", { month: "short" });
  return `${date.getDate()} ${monthName} ${date.getFullYear()}`;
}

export function formatDateAndTimePickerDate(date: Date) {
  const monthName = date.toLocaleString("en", { month: "short" });
  const year = date.getFullYear();
  const day = date.getDate();
  const hours = formatNumWithBit(date.getHours());
  const minutes = formatNumWithBit(date.getMinutes());
  return `${monthName} ${day}, ${year} ${hours}:${minutes}`;
}

/** @description format 2020-10-10T01:10:01+08:00 to Oct 10, 2020, 01:10:01, 2020-10-10T01:10:01+06:00 to Oct 10, 2020, 01:10:01 */
export function formatISO8601Time(
  v?: string,
  withoutHours?: boolean,
  type?: "withoutSeconds" | "weekDay"
) {
  if (!v) return "-";
  const utcOffset = v.slice(-6);
  if (utcOffset[0] === "+" || utcOffset === "-")
    return formatColumnDate(v.slice(0, -6), withoutHours, type);
  return formatColumnDate(v, withoutHours, type);
}

/**
 * @description format 1665335401000 to 2020-10-10T01:10:01+08:00
 * @param utcOffset +08:00 / +02:00 / ...
 */
export function formatMillionSecondsToISO8601Time(
  v: number,
  utcOffset?: string
) {
  if (!v) return "";
  const date = new Date(v);
  const year = date.getFullYear();
  const month = formatNumWithBit(date.getMonth() + 1);
  const day = formatNumWithBit(date.getDate());
  const hours = formatNumWithBit(date.getHours());
  const minutes = formatNumWithBit(date.getMinutes());
  const seconds = formatNumWithBit(date.getSeconds());
  let str = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  if (utcOffset) str = `${str}${utcOffset}`;
  return str;
}

/** @description format start & end ("2022-06-20 14:30:03") to May 1 - May 31, 2022 */
export function formatPeriodDate(
  start?: string | number,
  end?: string | number
) {
  if (!start && !end) return "";
  const pattern = "MMM D, YYYY";
  const s = start ? moment(start) : undefined;
  const e = end ? moment(end) : undefined;
  if (s && !e) return s.format(pattern);
  if (e && !s) return e.format(pattern);
  if (s!.clone().isSame(e!.clone(), "day")) return e!.format(pattern);
  if (s!.clone().isSame(e!.clone(), "year"))
    return `${s!.format("MMM D")} - ${e!.format(pattern)}`;
  return `${s!.format(pattern)} - ${e!.format(pattern)}`;
}
