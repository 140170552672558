import useSWR from "swr";
import moment from "moment-timezone";
import request, {
  ListRequestMaxLength,
  requestListDataInBatches,
} from "../request";
import type { IRequestParams, IRequestParamsInBatches } from "../request";
import { TimeUnit } from "utils/date";
import { getTokenValue } from "../request/accessToken";
import { InventoryType } from "constants/products";
import { formText, productText } from "langs/formatText";

export enum BookingOrderStatus {
  ONGOING = "ONGOING",
  UNPAID = "UNPAID",
  PAYMENT_PROCESSING = "PAYMENT_PROCESSING",
  PAID = "PAID",
  REFUNDED = "REFUNDED",
  PARTIAL_REFUNDED = "PARTIAL_REFUNDED",
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",
  UNKNOWN = "UNKNOWN",
  CANCELLED = "CANCELLED",
}

export const BookingOrderStatusText = {
  [BookingOrderStatus.ONGOING]: formText.OngoingText,
  [BookingOrderStatus.UNPAID]: productText.AwaitingPaymentText,
  [BookingOrderStatus.COMPLETED]: productText.CompletedText,
  [BookingOrderStatus.CANCELLED]: productText.CancelledText,
  [BookingOrderStatus.PAYMENT_PROCESSING]: productText.ProcessingPaymentText,
  [BookingOrderStatus.REFUNDED]: productText.RefundedText,
  [BookingOrderStatus.PARTIAL_REFUNDED]: productText.PartiallyRefundedText,
  [BookingOrderStatus.PAID]: productText.BookedText,
  [BookingOrderStatus.EXPIRED]: productText.ExpiredText,
};

export const BookingOrderStatusTextForUsage = {
  ...BookingOrderStatusText,
  [BookingOrderStatus.PAID]: formText.PaidText,
};

export enum BookingUsageStatus {
  UPCOMING = "UPCOMING",
  PENDING = "PENDING",
  ONGOING = "ONGOING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export const BookingUsageStatusArr = [
  BookingUsageStatus.UPCOMING,
  BookingUsageStatus.PENDING,
  BookingUsageStatus.ONGOING,
  BookingUsageStatus.COMPLETED,
  BookingUsageStatus.CANCELLED,
];

export const BookingUsageStatusText = {
  [BookingUsageStatus.UPCOMING]: productText.UpcomingText,
  [BookingUsageStatus.PENDING]: productText.PendingText,
  [BookingUsageStatus.ONGOING]: formText.OngoingText,
  [BookingUsageStatus.COMPLETED]: productText.CompletedText,
  [BookingUsageStatus.CANCELLED]: productText.CancelledText,
};

export enum APPOrderType {
  WALK_IN = "WALK_IN",
  BOOKING = "BOOKING",
}

export const APPOrderText = {
  [APPOrderType.WALK_IN]: productText.WalkInText,
  [APPOrderType.BOOKING]: productText.BookingText,
};

export enum MinimumBookableTimeType {
  PT30M = "PT30M",
  PT1H = "PT1H",
}

export const MinimumBookableTimeValue = {
  [MinimumBookableTimeType.PT30M]: 30,
  [MinimumBookableTimeType.PT1H]: 60,
};

export const getBookingCalendar = async (
  _url: string,
  page: number,
  size: number,
  spaceId: string,
  inventoryType: InventoryType,
  date: string,
  city: string,
  capacities?: Array<{ gte: number; lte: number }>
) =>
  request<TGetBookingCalendarResp>({
    method: "POST",
    path: `/space-partner/v1/bookings/calendar`,
    refreshOnUnauthorized: true,
    data: {
      page,
      size,
      inventoryType,
      date,
      city,
      capacities,
      spaceId: spaceId || undefined,
    },
  });

export function useBookingCalendar(
  page: number,
  size: number,
  spaceId: string,
  inventoryType: InventoryType,
  date: string,
  city?: string,
  capacities?: Array<{ gte: number; lte: number }>
) {
  const accessToken = getTokenValue();
  const { data, mutate, isValidating } = useSWR(
    [
      `${accessToken}/bookings/calendar`,
      page,
      size,
      spaceId,
      inventoryType,
      date,
      city,
      capacities,
    ],
    getBookingCalendar,
    {
      refreshInterval: TimeUnit.Minute * 5,
    }
  );
  let total = 0;
  let result: TBookingCalendarItem[] = [];
  let start: number | null = null;
  let end: number | null = null;
  if (data && data.items) {
    total = data.total || 0;
    let ds = [...data.items].filter((i) => !!i.openingHours?.length);
    ds.forEach((i: TBookingCalendarItem) => {
      // map get list start and end hour
      if (i.openingHours) {
        i.openingHours.forEach((v: TSpaceHoursItem) => {
          const oriStartTime = formatOpeningHour(v.startTime);
          const oriEndTime = formatOpeningHour(v.endTime);
          const startTime = Math.floor(oriStartTime);
          const endTime = Math.ceil(oriEndTime);
          i.start = startTime;
          i.end = endTime;
          if (!start) start = startTime;
          if (!end) end = endTime;
          if (start > startTime) start = startTime;
          if (end < endTime) end = endTime;
        });
      }
    });
    // data.items.sort((i, j) => i.start - j.start);
    if (start === null || end === null) ds = [];
    if (start !== null && end !== null) {
      ds.forEach((i: TBookingCalendarItem) => {
        // set each item disabletime
        const { bookings } = i;
        const disableTimes: Array<Partial<TBookingDetail>> = [];
        if (i.openingHours) {
          let preStart = start;
          let preEnd = end;
          i.openingHours.forEach((v: TSpaceHoursItem, index) => {
            const startTime = formatOpeningHour(v.startTime);
            const endTime = formatOpeningHour(v.endTime);
            if (preStart && startTime > preStart) {
              const pushData: Partial<TBookingDetail> = {
                start: preStart,
                end: startTime,
                type: "disable",
              };
              disableTimes.push(pushData);
            }
            preStart = endTime;
            if (
              index === i.openingHours.length - 1 &&
              preEnd &&
              endTime < preEnd
            ) {
              const pushData: Partial<TBookingDetail> = {
                start: endTime,
                end: preEnd,
                type: "disable",
              };
              disableTimes.push(pushData);
            }
          });
        }
        const formatBookings = [...bookings]
          .filter((bv) => !!(bv.startTime && bv.endTime))
          .map((bookingItem) => {
            bookingItem.type = bookingItem.order?.status;
            if (
              !bookingItem.type &&
              bookingItem.source === "SPACE_PROVIDER_PORTAL"
            ) {
              bookingItem.type = BookingOrderStatus.PAID;
            }
            const momentStart = moment(bookingItem.startTime)
              .clone()
              .tz(i.timeZone);
            const momentEnd = moment(bookingItem.endTime)
              .clone()
              .tz(i.timeZone);
            bookingItem.start = momentStart.hour() + momentStart.minute() / 60;
            bookingItem.end = momentEnd.hour() + momentEnd.minute() / 60;
            return bookingItem;
          });
        i.bookings = [...formatBookings, ...disableTimes];
      });
    }
    result = [...ds];
  }
  return {
    result,
    mutate,
    isValidating,
    total,
    TimelineStart: start || 0,
    TimelineEnd: end || 24,
  };
}

export function formatOpeningHour(time: string) {
  return (
    parseInt(time.split(":")[0], 10) + parseInt(time.split(":")[1], 10) / 60
  );
}

export const submitPortalBooking = async (
  startTime: string, // 2020-01-22T10:00:00Z
  endTime: string,
  inventoryId: string,
  clientName: string,
  notes?: string
) =>
  request({
    method: "POST",
    path: `/space-partner/v1/bookings`,
    refreshOnUnauthorized: true,
    data: { startTime, endTime, inventoryId, clientName, notes },
  });

export const submitEditBooking = async (
  id: string,
  clientName: string,
  notes?: string
) =>
  request({
    method: "PUT",
    path: `/space-partner/v1/bookings/${id}`,
    refreshOnUnauthorized: true,
    data: { clientName, notes },
  });

export const submitCancelBooking = async (id: string) =>
  request({
    method: "POST",
    path: `/space-partner/v1/bookings/${id}/cancel`,
    refreshOnUnauthorized: true,
    data: { id },
  });

export const getBookingUsage = async (
  _url: string,
  page: number,
  size: number,
  status: BookingUsageStatus,
  startTime?: string,
  endTime?: string,
  city?: string,
  inventoryType?: string, // InventoryType str
  spaceId?: string,
  capacities?: Array<{ gte: number; lte: number }>,
  sort?: SortType
) => {
  const params: IRequestParams = {
    method: "POST",
    path: `/space-partner/v1/bookings/list`,
    refreshOnUnauthorized: true,
    data: {
      page,
      size,
      inventoryTypes: inventoryType ? inventoryType.split(",") : undefined,
      startTime,
      endTime,
      city,
      capacities,
      spaceId,
      status,
      sort: sort ? `createdAt,${sort}` : undefined,
    },
  };
  if (size > ListRequestMaxLength && page === 1) {
    // from download request
    return requestListDataInBatches<TBookingListResp>(
      params as IRequestParamsInBatches,
      size
    );
  }
  return request<TBookingListResp>(params);
};

export function useBookingUsage(
  page: number,
  size: number,
  status: BookingUsageStatus,
  start?: string,
  end?: string,
  city?: string,
  inventoryType?: string, // InventoryType str
  spaceId?: string,
  capacities?: Array<{ gte: number; lte: number }>,
  sort?: SortType
) {
  const accessToken = getTokenValue();
  const { data, mutate, isValidating } = useSWR(
    [
      `${accessToken}/bookings/list`,
      page,
      size,
      status,
      start,
      end,
      city,
      inventoryType,
      spaceId,
      capacities,
      sort,
    ],
    getBookingUsage,
    { refreshInterval: TimeUnit.Minute * 5 }
  );
  const total = data?.total || 0;
  let result: TBookingDetail[] = [];
  let currency = "";
  if (data && data.items) {
    result = [...data.items];
  }
  return {
    result,
    mutate,
    isValidating,
    total,
    currency,
  };
}

export const getBookingUsageCounts = async (
  _url: string,
  city?: string,
  spaceId?: string
) =>
  request<{ upcoming: number; ongoing: number; pending: number }>({
    method: "GET",
    path: `/space-partner/v1/bookings/count`,
    refreshOnUnauthorized: true,
    data: { city, spaceId },
  });

export function useBookingCountLoader(city?: string, spaceId?: string) {
  const accessToken = getTokenValue();
  const { data, mutate, isValidating } = useSWR(
    accessToken && city
      ? [`${accessToken}/bookings/count`, city, spaceId]
      : null,
    getBookingUsageCounts,
    { refreshInterval: TimeUnit.Minute * 5, shouldRetryOnError: !!accessToken }
  );
  return { data, mutate, isValidating };
}

export const reviewBooking = async (
  id: string | number,
  approved: boolean,
  rejectReason?: string
) =>
  request({
    method: "POST",
    path: `/space-partner/v1/bookings/${id}/review`,
    refreshOnUnauthorized: true,
    data: { approved, rejectReason },
  });

export const confirmReviewBooking = async (
  id: string | number,
  approved: boolean
) =>
  request({
    method: "POST",
    path: `/space-partner/v1/bookings/${id}/confirm/review`,
    refreshOnUnauthorized: true,
    data: { approved },
  });
