import React, { useEffect, useState, useRef } from "react";
import ReactDom from "react-dom";

import styled from "styled-components";
import { AbsoluteFill, FlexCenter, FlexColumn } from "styles/common";
import { None } from "styles/colors";

interface ModalProps {
  open?: boolean;
  children?: React.ReactNode;
}

export function Modal({ open, children }: ModalProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef(document.getElementById("modals"));
  const containerRef = useRef(document.createElement("div"));
  const { _open, enter, leave } = useModalEffect(!!open, 400);
  useEffect(() => {
    const rootEl = rootRef.current;
    if (!rootEl) return;
    const container = containerRef.current;
    rootEl.appendChild(container);
    return () => {
      rootEl.removeChild(container);
    };
  }, []);
  if (!_open || !children || !rootRef.current) return null;
  const content = (
    <ModalWrapper enter={enter} leave={leave} ref={scrollRef}>
      <ModalBG enter={enter} leave={leave} />
      {attachProps(children as any, { enter, leave })}
    </ModalWrapper>
  );
  return ReactDom.createPortal(content, containerRef.current);
}

export function useModalEffect(open: boolean, duration = 1000) {
  const [_open, _setOpen] = useState(false);
  const [_enter, _setEnter] = useState(false);
  const [_leave, _setLeave] = useState(false);
  useEffect(() => {
    if (open) _setOpen(true);
    if (!open) _setLeave(true);
  }, [open]);
  useEffect(() => {
    if (_open) _setEnter(true);
    if (!_open) _setEnter(false);
  }, [_open]);
  useEffect(() => {
    // leave
    if (_leave) {
      const timer = window.setTimeout(() => {
        _setOpen(false);
        _setLeave(false);
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [_leave, duration]);
  useEffect(() => {
    // clear
    return () => {
      _setLeave(false);
      _setOpen(false);
    };
  }, []);
  return {
    enter: _enter,
    leave: _leave,
    _open,
  };
}

export const attachProps = (
  children?: React.ReactElement<any> | Array<React.ReactElement<any>>,
  props?: object
) => {
  if (!children || !props) return children;
  if (children instanceof Array) {
    return children.map((child) => React.cloneElement(child, props));
  }
  return React.cloneElement(children, props);
};

interface AnimProps {
  enter?: boolean;
  leave?: boolean;
}

function getAnimBG(p: AnimProps) {
  if (p.leave) return None;
  if (p.enter) return "rgba(0, 0, 0, 0.6)";
  return None;
}

function getAnimOpacity(p: AnimProps) {
  if (p.leave) return 0;
  if (p.enter) return 1;
  return 0;
}

export const ModalWrapper = styled.div<AnimProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
  ${FlexColumn}
  ${FlexCenter}
  opacity: ${(p) => getAnimOpacity(p)};
  transition: opacity 0.2s ease;
`;

export const ModalBG = styled.div<AnimProps>`
  ${AbsoluteFill}
  background-color: ${(p) => getAnimBG(p)};
  transition: background-color 0.2s ease;
  touch-action: none;
`;
