import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { message as MobileLoginToast } from "components/SiteItems/MobileLoginToast";
import { SiteNav } from "constants/navigators";
import { usePortalStyle } from "store/usePortalStyle";
import { useAccessToken } from "store/accessToken";
import { useMobile } from "hooks/useMobile";

import PortalLayout, { MinTableWIdth } from "components/PortalLayout";

const CheckIn = lazy(() => import("pages/CheckIn"));
const Overall = lazy(() => import("pages/Overall"));
const Spaces = lazy(() => import("pages/Spaces"));
const Bookings = lazy(() => import("pages/Bookings"));
const BookingsUsage = lazy(() => import("pages/BookingUsage"));
const Role = lazy(() => import("pages/Role"));
const Admin = lazy(() => import("pages/Admin"));
const Invoice = lazy(() => import("pages/Invoice"));
const RevenueReport = lazy(() => import("pages/RevenueReport"));

export const HomeRoutes = [
  // dashboard
  { path: `/${SiteNav.DASHBOARD}`, key: SiteNav.DASHBOARD, component: Overall },
  // spaces
  { path: `/${SiteNav.SPCAES}`, key: SiteNav.SPCAES, component: Spaces },
  // walk-ins
  { path: `/${SiteNav.USAGE}`, key: SiteNav.USAGE, component: CheckIn },
  // bookings
  { path: `/${SiteNav.BOOKINGS}`, key: SiteNav.BOOKINGS, component: Bookings },
  {
    path: `/${SiteNav.BOOKINGS_USAGE}/:type?`,
    key: SiteNav.BOOKINGS_USAGE,
    component: BookingsUsage,
  },
  // roles
  {
    path: `/${SiteNav.ROLE_MANAGEMENT}`,
    key: SiteNav.ROLE_MANAGEMENT,
    component: Role,
  },
  // admins
  {
    path: `/${SiteNav.ADMIN_MANAGEMENT}`,
    key: SiteNav.ADMIN_MANAGEMENT,
    component: Admin,
  },
  // invoice
  {
    path: `/${SiteNav.INVOICE}`,
    key: SiteNav.INVOICE,
    component: Invoice,
  },
  // revenue report
  {
    path: `/${SiteNav.REVENUE_REPORT}`,
    key: SiteNav.REVENUE_REPORT,
    component: RevenueReport,
  },
];
const Tip = MobileLoginToast?.init();

export default function Home() {
  const { height } = usePortalStyle();
  const { curCountry } = useAccessToken();
  const { isMobile } = useMobile();
  if (!curCountry) return <div />;
  return (
    <PortalLayout
      minContentWidth={MinTableWIdth}
      isViewHeight={true}
      minHeight={height}
    >
      <Suspense fallback={<div />}>
        <Switch>
          {HomeRoutes.map((c) => (
            <Route path={c.path} key={c.key} component={c.component} />
          ))}
        </Switch>
      </Suspense>
      {isMobile && Tip}
    </PortalLayout>
  );
}
