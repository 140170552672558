import { useState, useEffect, useMemo, useCallback } from "react";
import { createContainer } from "unstated-next";

import { useAccessToken } from "./accessToken";
import { getPartners } from "service/v1/spaceCode";
import useManualFetcher from "hooks/useManualFetcher";
import { toObject } from "utils/index";
import { SpaceCodeUsageType } from "constants/products";

const container = createContainer(() => {
  const { accessToken } = useAccessToken();
  const [data, setData] = useState<TSpaceCodeAssignUser[]>([]);
  const [shouldReload, setShouldReload] = useState(false);
  const reloadSpace = useCallback(() => setShouldReload((v) => !v), []);
  const dataObj = useMemo(
    () => toObject(data, (i) => i.assignAdminIdentityId),
    [data]
  );
  const defaultCapexInvestor = useMemo(
    () => data.filter((i) => !!i.defaultCapexInvestor),
    [data]
  );
  const spacePartners = useMemo(() => {
    if (!(data && data.length)) return [];
    const ds = [...data].filter((i) => !!i.assignAdminIdentityId);
    const result: SearchPickerBaseKeys[] = ds
      .filter((i) => i.usageType === SpaceCodeUsageType.PUBLIC)
      .map((i) => ({
        name: i.brandInPortalName || "-",
        id: i.assignAdminIdentityId,
      }));
    if (result.length) {
      result.unshift({
        name: "Space Partner",
        id: "Space Partner",
        isTitle: true,
      });
    }
    return result;
  }, [data]);
  const partnersForBusiness = useMemo(() => {
    if (!(data && data.length)) return [];
    const ds = [...data].filter((i) => !!i.assignAdminIdentityId);
    const result: SearchPickerBaseKeys[] = ds
      .filter((i) => i.usageType === SpaceCodeUsageType.PRIVATE)
      .map((i) => ({
        name: i.brandInPortalName || "-",
        id: i.assignAdminIdentityId,
      }));
    if (result.length) {
      result.unshift({
        name: "Switch for Business",
        id: "Switch for Business",
        isTitle: true,
        style: { marginTop: 16 },
      });
    }
    return result;
  }, [data]);
  const filterDS = useMemo(() => {
    if (!(data && data.length)) return [];
    return [...spacePartners, ...partnersForBusiness];
  }, [data, spacePartners, partnersForBusiness]);
  const { isLoading, doFetch } = useManualFetcher(getPartners);
  useEffect(() => {
    const service = async () => {
      try {
        const resp = await doFetch();
        if (resp && Array.isArray(resp)) setData(resp);
      } catch (e) {
        // formatError(e, "");
        console.log("Get spaces err: ", (e as any)?.message || "service error");
      }
    };
    if (accessToken && accessToken.accessToken) service();
  }, [doFetch, accessToken, shouldReload]);
  return {
    isLoading,
    data,
    dataObj,
    reloadSpace,
    filterDS,
    spacePartners,
    partnersForBusiness,
    defaultCapexInvestor,
  };
});

export const PartnersProvider = container.Provider;
export const usePartnersLoader = container.useContainer;
export default container;
