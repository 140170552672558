import PortalLayout from "./PortalLayout";

export default PortalLayout;

export {
  HeaderHeight,
  MinWindowWidth,
  LeftSiderWidth,
  MinTableWIdth,
} from "./style.PortalLayout";
