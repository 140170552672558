import styled from "styled-components";
import * as C from "styles/colors";
import { Shadow } from "styles/common";

// 因为 dropdown 里面的内容差别很大，这里不应该加 border 和 padding，留给具体调用的地方自己定义
export const DropdownOverlay = styled.div`
  background: ${C.White};
  box-shadow: ${Shadow};
  border-radius: 2px;
`;
