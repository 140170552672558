import intl from "langs/intl";

// form label
export const SpaceProviderPortalText = intl.formatMessage({
  defaultMessage: "Space Partner Portal",
});
export const WorkAnywhereText = intl.formatMessage({
  defaultMessage: "work anywhere",
});
export const LogInToYourAccountText = intl.formatMessage({
  defaultMessage: "Log in to Your Account",
});
export const LogOutText = intl.formatMessage({
  defaultMessage: "Log Out",
});
export const ClientNameText = intl.formatMessage({
  defaultMessage: "Client name",
});
export const UnavailableText = intl.formatMessage({
  defaultMessage: "Unavailable",
});
export const FromText = intl.formatMessage({
  defaultMessage: "From",
  description: "eg: From May 3, 2021 To May 6, 2021",
});
export const ToText = intl.formatMessage({
  defaultMessage: "To",
  description: "eg: From May 3, 2021 To May 6, 2021",
});
export const PerText = intl.formatMessage({ defaultMessage: "per" });
export const UnitText = intl.formatMessage({ defaultMessage: "Unit" });
export const StartDateText = intl.formatMessage({
  defaultMessage: "Start date",
});
export const EndDateText = intl.formatMessage({
  defaultMessage: "End date",
});
export const RateText = intl.formatMessage({ defaultMessage: "Rate" });
export const DiscountRateText = intl.formatMessage({
  defaultMessage: "Discount Rate",
});
export const DiscountTypeText = intl.formatMessage({
  defaultMessage: "Discount type",
});
export const BookingStartTimeText = intl.formatMessage({
  defaultMessage: "Booking start time",
});
export const CancellationPolicyText = intl.formatMessage({
  defaultMessage: "Cancellation policy",
});
export const CancelTimeText = intl.formatMessage({
  defaultMessage: "Cancel Time",
});
export const CancellationFeeText = intl.formatMessage({
  defaultMessage: "Cancellation Fee",
});
export const OrderNoText = intl.formatMessage({ defaultMessage: "Order No" });
export const NoteText = intl.formatMessage({ defaultMessage: "Note" });
export const FilterText = intl.formatMessage({ defaultMessage: "Filter" });
export const CapacityText = intl.formatMessage({ defaultMessage: "Capacity" });
export const LocationText = intl.formatMessage({ defaultMessage: "Location" });
export const SubtypeText = intl.formatMessage({ defaultMessage: "Subtype" });
export const GTVTooltip = intl.formatMessage({
  defaultMessage: "Gross Transactional Value",
});
export const BookingTypeText = intl.formatMessage({
  defaultMessage: "Booking type",
});
export const TypeText = intl.formatMessage({
  defaultMessage: "Type",
});
export const SpaceNameText = intl.formatMessage({
  defaultMessage: "Space Name",
});
export const ScannerIDText = intl.formatMessage({
  defaultMessage: "Scanner ID",
});
export const ScannerNumberText = intl.formatMessage({
  defaultMessage: "Scanner Number",
});
export const AllSPSFBText = intl.formatMessage({
  defaultMessage: "All Space partner/Switch for Business",
});
export const AllSFBText = intl.formatMessage({
  defaultMessage: "All Switch for Business Accounts",
});
export const LastActiveTimeText = intl.formatMessage({
  defaultMessage: "Last Active Time",
});
export const SearchIDText = intl.formatMessage({
  defaultMessage: "Search ID",
});
export const InventoryTypeText = intl.formatMessage({
  defaultMessage: "Inventory Type",
});
export const RecepientText = intl.formatMessage({
  defaultMessage: "Recepient",
});
export const FormInventoryTypeText = intl.formatMessage({
  defaultMessage: "Inventory type",
});
export const UploadImagesText = intl.formatMessage({
  defaultMessage: "Upload images",
});
export const DropImagesText = intl.formatMessage({
  defaultMessage: "or drop images here",
});
export const OrderIDText = intl.formatMessage({
  defaultMessage: "Order ID",
});
export const PublicText = intl.formatMessage({
  defaultMessage: "Public",
  description: "space code usage type: public or private",
});
export const PrivateText = intl.formatMessage({
  defaultMessage: "Private",
  description: "space code usage type: public or private",
});
export const PhoneNumberText = intl.formatMessage({
  defaultMessage: "Phone Number",
});
export const LastDigitsOFPhoneText = intl.formatMessage({
  defaultMessage: "Last 4 Digits of Phone",
});
export const OrderSourceText = intl.formatMessage({
  defaultMessage: "Order Source",
});
export const PaymentStatusText = intl.formatMessage({
  defaultMessage: "Payment Status",
});
export const PaymentAmountText = intl.formatMessage({
  defaultMessage: "Payment Amount",
});
export const ReasonText = intl.formatMessage({
  defaultMessage: "Reason",
});
export const StartDateTimeText = intl.formatMessage({
  defaultMessage: "Start Date&Time",
});
export const EndDateTimeText = intl.formatMessage({
  defaultMessage: "End Date&Time",
});
export const CheckInDateTimeText = intl.formatMessage({
  defaultMessage: "Check-in Date&Time",
});
export const CheckOutDateTimeText = intl.formatMessage({
  defaultMessage: "Check-out Date&Time",
});
export const CheckOutMethodText = intl.formatMessage({
  defaultMessage: "Check-Out Method",
});
export const CreateTimeText = intl.formatMessage({
  defaultMessage: "Create Time",
});
export const AccountNameText = intl.formatMessage({
  defaultMessage: "SFB",
  description: "Switch for Business",
});
export const WayfindingInfoText = intl.formatMessage({
  defaultMessage: "Wayfinding info",
});
export const CarparkNameText = intl.formatMessage({
  defaultMessage: "Nearest carpark name",
});
export const PleaseInputCarparkNameText = intl.formatMessage({
  defaultMessage: "Please input nearest carpark name.",
});
export const CarparkAddressText = intl.formatMessage({
  defaultMessage: "Carpark address",
});
export const PleaseInputCarparkAddressText = intl.formatMessage({
  defaultMessage: "Please input carpark address.",
});
export const CarDropOffPointText = intl.formatMessage({
  defaultMessage: "Car drop-off point",
});
export const MaximumCapacityText = intl.formatMessage({
  defaultMessage: "Maximum capacity",
});
export const MinimumCapacityText = intl.formatMessage({
  defaultMessage: "Minimum capacity",
});
export const PleaseInputMaximumCapacityText = intl.formatMessage({
  defaultMessage: "Please input maximum capacity.",
});
export const PleaseInputMinimumCapacityText = intl.formatMessage({
  defaultMessage: "Please input minimum capacity.",
});
export const PleaseSelectBusinessIDText = intl.formatMessage({
  defaultMessage: "Please select business id.",
});
export const PleaseSelectSpacePartnerText = intl.formatMessage({
  defaultMessage: "Please select space partner.",
});
export const SpacePartnerText = intl.formatMessage({
  defaultMessage: "Space partner",
});
export const SpaceForBusinessText = intl.formatMessage({
  defaultMessage: "Space for business",
});
export const InventoryNameText = intl.formatMessage({
  defaultMessage: "Inventory Name",
});
export const FormInventoryNameText = intl.formatMessage({
  defaultMessage: "Inventory name",
  description: "form label",
});
export const PleaseInputInventoryNameText = intl.formatMessage({
  defaultMessage: "Please input inventory name.",
});
export const PleaseSelectInventoryTypeText = intl.formatMessage({
  defaultMessage: "Please select inventory type.",
});
export const PleaseSelectKeyCardTypeText = intl.formatMessage({
  defaultMessage: "Please select key card type.",
});
export const MinimumBookingTimeText = intl.formatMessage({
  defaultMessage: "Minimum booking time",
});
export const EarliestBookableTimeText = intl.formatMessage({
  defaultMessage: "Earliest bookable time",
});
export const PleaseSelectEarliestBookableTimeText = intl.formatMessage({
  defaultMessage: "Please select earliest bookable time.",
});
export const FarthestBookableTimeText = intl.formatMessage({
  defaultMessage: "Farthest bookable time",
});
export const PleaseSelectFarthestBookableTimeText = intl.formatMessage({
  defaultMessage: "Please select farthest bookable time.",
});
export const PublishStatusText = intl.formatMessage({
  defaultMessage: "Publish Status",
});
export const ListingStatusText = intl.formatMessage({
  defaultMessage: "Listing Status",
});
export const CurrentStatusText = intl.formatMessage({
  defaultMessage: "Current Status",
});
export const OnTheHourText = intl.formatMessage({
  defaultMessage: "On the hour",
});
export const OnTheHalfHourText = intl.formatMessage({
  defaultMessage: "On the half hour",
});
export const BookingRateText = intl.formatMessage({
  defaultMessage: "Booking Rate",
});
export const NearestSubwayStationText = intl.formatMessage({
  defaultMessage: "Nearest subway/train station",
});
export const PleaseInputNearestSubwayStationText = intl.formatMessage({
  defaultMessage: "Please input nearest subway/train station.",
});
export const FrontEntranceText = intl.formatMessage({
  defaultMessage: "Front entrance",
});
export const DescriptionText = intl.formatMessage({
  defaultMessage: "Description",
});
export const CommentText = intl.formatMessage({
  defaultMessage: "Comment",
});
export const PleaseInputCommentText = intl.formatMessage({
  defaultMessage: "Please input comment",
});
export const SameAsOpeningHoursText = intl.formatMessage({
  defaultMessage: "Same as opening hours",
});
export const SameAsSpaceOpeningHoursText = intl.formatMessage({
  defaultMessage: "Same as space opening hours",
});
export const SwitchOpeningHoursText = intl.formatMessage({
  defaultMessage: "Switch opening hours.",
});
export const OpeningOnHolidayText = intl.formatMessage({
  defaultMessage: "Open on Public Holidays",
});
export const OpeningOnHolidayTipText = intl.formatMessage({
  defaultMessage:
    "If you need to set up different opening hours for public holidays, please go to the Special Closing Time settings below.",
});
export const FrontDeskOperatingHoursDetailText = intl.formatMessage({
  defaultMessage: "Front desk operating hours detail",
});
export const FrontDeskOperatingHoursText = intl.formatMessage({
  defaultMessage: "Front desk operating hours",
});
export const AddFrontDeskOperatingHoursText = intl.formatMessage({
  defaultMessage: "Add front desk operating hours",
});
export const AddQRCodeRecrpientText = intl.formatMessage({
  defaultMessage: "Add QR code recepient",
});
export const FormQRCodeRecrpientText = intl.formatMessage({
  defaultMessage: "QR code recepient",
});
export const PleaseInputQRCodeRecrpientText = intl.formatMessage({
  defaultMessage: "Please input QR code recepient",
});
export const PleaseInputCorrectEmailText = intl.formatMessage({
  defaultMessage: "Please input correct email",
});
export const ACHoursDetailText = intl.formatMessage({
  defaultMessage: "AC Hours detail",
});
export const ACHoursText = intl.formatMessage({
  defaultMessage: "AC Hours",
});
export const OpeningHoursText = intl.formatMessage({
  defaultMessage: "Opening hours",
});
export const AddACHoursText = intl.formatMessage({
  defaultMessage: "Add AC hours",
});
export const AddSpecialClosingScheduleText = intl.formatMessage({
  defaultMessage: "Add special closing schedule",
});
export const PleaseInputDescriptionText = intl.formatMessage({
  defaultMessage: "Please input description.",
});
export const WiFiNameText = intl.formatMessage({
  defaultMessage: "WiFi name",
});
export const PleaseInputWiFiNameText = intl.formatMessage({
  defaultMessage: "Please input WiFi name.",
});
export const WiFiPasswordText = intl.formatMessage({
  defaultMessage: "WiFi password",
});
export const PleaseInputWiFiPasswordText = intl.formatMessage({
  defaultMessage: "Please input WiFi password.",
});
export const SpaceCodeText = intl.formatMessage({
  defaultMessage: "Space code",
});
export const FormSpaceNameText = intl.formatMessage({
  defaultMessage: "Space name",
  description: "form label",
});
export const FormLocationNameText = intl.formatMessage({
  defaultMessage: "Location name",
  description: "form label",
});
export const FormDistrictNameText = intl.formatMessage({
  defaultMessage: "District",
  description: "District",
});
export const FormScannerNameText = intl.formatMessage({
  defaultMessage: "Scanner name",
  description: "form label",
});
export const FormProductUnitIdText = intl.formatMessage({
  defaultMessage: "Inventory Id",
  description: "form label",
});
export const FormScannerBrandText = intl.formatMessage({
  defaultMessage: "Scanner brand",
  description: "form label",
});
export const FormScannerVersionText = intl.formatMessage({
  defaultMessage: "Scanner version",
  description: "form label",
});
export const ScannerVersionText = intl.formatMessage({
  defaultMessage: "Scanner Version",
});
export const FormStaticIPText = intl.formatMessage({
  defaultMessage: "Static IP",
});
export const FormCommunicationProtocolText = intl.formatMessage({
  defaultMessage: "Communication protocol",
});
export const FormCapexInvestorText = intl.formatMessage({
  defaultMessage: "Capex investor",
});
export const FormCapexAmountText = intl.formatMessage({
  defaultMessage: "Capex amount",
});
export const FormNeedWifiText = intl.formatMessage({
  defaultMessage: "Need Wifi",
});
export const PleaseInputCapexInvestorText = intl.formatMessage({
  defaultMessage: "Please input capex investor.",
});
export const PleaseInputCapexAmountText = intl.formatMessage({
  defaultMessage: "Please input capex amount.",
});
export const PleaseInputSpaceNameText = intl.formatMessage({
  defaultMessage: "Please input space name.",
});
export const PleaseSelectSpaceText = intl.formatMessage({
  defaultMessage: "Please input space name.",
});
export const PleaseInputLocationNameText = intl.formatMessage({
  defaultMessage: "Please input location name.",
});
export const PleaseInputScannerNameText = intl.formatMessage({
  defaultMessage: "Please input scanner name.",
});
export const PleaseSelectProductUnitIdText = intl.formatMessage({
  defaultMessage: "Please select product inventory.",
});
export const PleaseInputScannerBrandText = intl.formatMessage({
  defaultMessage: "Please input scanner brand.",
});
export const PleaseSelectScannerVersionText = intl.formatMessage({
  defaultMessage: "Please select scanner version.",
});
export const AddressText = intl.formatMessage({
  defaultMessage: "Address",
});
export const PleaseInputAddressText = intl.formatMessage({
  defaultMessage: "Please input address.",
});
export const PleaseSelectCountryText = intl.formatMessage({
  defaultMessage: "Please select country.",
});
export const PleaseSelectCityText = intl.formatMessage({
  defaultMessage: "Please select city.",
});
export const LatitudeText = intl.formatMessage({
  defaultMessage: "Latitude",
});
export const LatitudeShouldLessThanText = intl.formatMessage({
  defaultMessage: "Latitude should less than or equal to 180 deg.",
});
export const LatitudeShouldOverThanText = intl.formatMessage({
  defaultMessage: "Latitude should over than or equal to -180 deg.",
});
export const PleaseInputLatitudeText = intl.formatMessage({
  defaultMessage: "Please input latitude.",
});
export const PleaseSelectDistrictText = intl.formatMessage({
  defaultMessage: "Please select district.",
});
export const LongitudeText = intl.formatMessage({
  defaultMessage: "Longitude",
});
export const LongitudeShouldLessThanText = intl.formatMessage({
  defaultMessage: "Longitude should less than or equal to 180 deg.",
});
export const LongitudeShouldOverThanText = intl.formatMessage({
  defaultMessage: "Longitude should over than or equal to -180 deg.",
});
export const PleaseInputLongitudeText = intl.formatMessage({
  defaultMessage: "Please input longitude.",
});
export const BuildingEmergencyAndFCCNumberText = intl.formatMessage({
  defaultMessage: "Building emergency & FCC number",
});
export const PhoneNumberIsNotCorrectText = intl.formatMessage({
  defaultMessage: "Phone number is not correct.",
});
export const StartDateOrTimeText = intl.formatMessage({
  defaultMessage: "Start",
  description: "Start date or time",
});
export const EndDateOrTimeText = intl.formatMessage({
  defaultMessage: "End",
  description: "End date or time",
});
export const PleaseSelectStartDateText = intl.formatMessage({
  defaultMessage: "Please select start date.",
});
export const PleaseSelectEndDateText = intl.formatMessage({
  defaultMessage: "Please select end date.",
});
export const PleaseSelectStartTimeText = intl.formatMessage({
  defaultMessage: "Please select start time.",
});
export const PleaseSelectEndTimeText = intl.formatMessage({
  defaultMessage: "Please select end time.",
});
export const BlockGeneralInfoText = intl.formatMessage({
  defaultMessage: "General Info",
  description: "block title: General Info",
});
export const BlockFreeTrialText = intl.formatMessage({
  defaultMessage: "Free Trial",
  description: "block title: Free Trial",
});
export const BlockCapexText = intl.formatMessage({
  defaultMessage: "Capex",
  description: "block title: Capex",
});
export const BlockBountyText = intl.formatMessage({
  defaultMessage: "Bounty",
  description: "block title: Bounty",
});
export const BlockHoursText = intl.formatMessage({
  defaultMessage: "Hours",
  description: "block title: Hours",
});
export const BlockAmenitiesText = intl.formatMessage({
  defaultMessage: "Amenities",
  description: "block title: Amenities",
});
export const BlockPhotosText = intl.formatMessage({
  defaultMessage: "Photos",
  description: "block title: Photos",
});
export const BlockAdditionalInfoText = intl.formatMessage({
  defaultMessage: "Additional Info",
  description: "block title: Additional_Info",
});
export const BlockSpecialClosingTimeText = intl.formatMessage({
  defaultMessage: "Special Closing Time",
  description: "block title: Special_Closing_Time",
});
export const BlockAdditionalAmenitiesText = intl.formatMessage({
  defaultMessage: "Additional Amenities",
  description: "block title: Additional_Amenities",
});
export const BlockKeyCardsText = intl.formatMessage({
  defaultMessage: "Key Cards",
  description: "block title: Key_Cards",
});
export const BlockUsageTypeText = intl.formatMessage({
  defaultMessage: "Usage Type",
  description: "block title: Usage_Type",
});
export const SpaceClosedText = intl.formatMessage({
  defaultMessage: "Closed",
  description: "space is closed",
});
export const MinuteSlotText = (duration: number) =>
  intl.formatMessage(
    {
      description: "eg: 1 Minute / 2 Minutes",
      defaultMessage:
        "{duration, plural, =0 {# Minute} =1 {# Minute} other {# Minutes}}",
    },
    { duration }
  );
export const HourSlotText = (duration: number) =>
  intl.formatMessage(
    {
      description: "eg: 1 Hour / 2 Hours",
      defaultMessage:
        "{duration, plural, =0 {# Hour} =1 {# Hour} other {# Hours}}",
    },
    { duration }
  );
export const DayAheadSlotText = (duration: number) =>
  intl.formatMessage(
    {
      description: "eg: 1 day ahead / 2 days ahead",
      defaultMessage:
        "{duration, plural, =0 {Anytime} =1 {# day ahead} other {# days ahead}}",
    },
    { duration }
  );
export const HoursAheadSlotText = (duration: number) =>
  intl.formatMessage(
    {
      description: "eg: 1 hour ahead / 2 hours ahead",
      defaultMessage:
        "{duration, plural, =0 {Anytime} =1 {# hour ahead} other {# hours ahead}}",
    },
    { duration }
  );
export const DaySlotText = (duration: number) =>
  intl.formatMessage(
    {
      description: "eg: 1 day / 2 days",
      defaultMessage:
        "{duration, plural, =0 {Anytime} =1 {# day} other {# days}}",
    },
    { duration }
  );
export const WeekSlotText = (duration: number) =>
  intl.formatMessage(
    {
      description: "eg: 1 week / 2 weeks",
      defaultMessage:
        "{duration, plural, =0 {Anytime} =1 {# week} other {# weeks}}",
    },
    { duration }
  );
export const MonthSlotText = (duration: number) =>
  intl.formatMessage(
    {
      description: "eg: 1 month / 2 months",
      defaultMessage:
        "{duration, plural, =0 {Anytime} =1 {# month} other {# months}}",
    },
    { duration }
  );
export const HoursInAdvanceSlotText = (duration: number) =>
  intl.formatMessage(
    {
      description:
        "eg: At least 1 hour in advance / At least 2 hours in advance / Anytime",
      defaultMessage:
        "{duration, plural, =0 {Anytime} =1 {At least # hour in advance} other {At least # hours in advance}}",
    },
    { duration }
  );
export const CountryText = intl.formatMessage({
  defaultMessage: "Country",
});
export const CityText = intl.formatMessage({
  defaultMessage: "City",
});

// components
export const NoDiscountText = intl.formatMessage({
  defaultMessage: "No discount",
});
export const FilterAllSpacesText = intl.formatMessage({
  defaultMessage: "All Spaces",
  description: "filer option",
});
export const PeriodText = intl.formatMessage({ defaultMessage: "Period" });
export const OngoingText = intl.formatMessage({ defaultMessage: "Ongoing" });
export const NoCancellationAllowedText = intl.formatMessage({
  defaultMessage: "No cancellation allowed",
});
export const CancellationAllowedText = intl.formatMessage({
  defaultMessage: "Cancellation allowed",
});
export const PleaseInputRateText = intl.formatMessage({
  defaultMessage: "Please input rate.",
});
export const PleaseSelectBookingStartTimeText = intl.formatMessage({
  defaultMessage: "Please select booking start time.",
});
export const PleaseInputDiscountRateText = intl.formatMessage({
  defaultMessage: "Please input discount rate.",
});
export const DiscountCompareOriginalRateText = intl.formatMessage({
  defaultMessage: "Discount rate cannot be bigger than original rate.",
});
export const PleaseSelectDiscountPercentText = intl.formatMessage({
  defaultMessage: "Please select discount percent.",
});
export const PleaseInputCarDropOffPointText = intl.formatMessage({
  defaultMessage: "Please input car drop-off point.",
});
export const FreeText = intl.formatMessage({
  defaultMessage: "Free",
  description: "free or paid",
});
export const PaidText = intl.formatMessage({
  defaultMessage: "Paid",
  description: "free or paid",
});

// table
export const LastEditText = intl.formatMessage({ defaultMessage: "Last Edit" });
export const TotalGTVText = intl.formatMessage({ defaultMessage: "Total GTV" });
export const MemberIDText = intl.formatMessage({ defaultMessage: "Member ID" });
export const CurrencyText = intl.formatMessage({ defaultMessage: "Currency" });
export const TotalUsageText = intl.formatMessage({
  defaultMessage: "Total Usage",
});
export const PaidUsageText = intl.formatMessage({
  defaultMessage: "Paid Usage",
});
export const AvgPaidUsageText = intl.formatMessage({
  defaultMessage: "Avg Paid Usage",
});
export const TotalStaysText = intl.formatMessage({
  defaultMessage: "Total Stays",
});
export const PaidStaysText = intl.formatMessage({
  defaultMessage: "Paid Stays",
});
export const TotalStayersText = intl.formatMessage({
  defaultMessage: "Total Stayers",
});
export const AvgNewStayersText = intl.formatMessage({
  defaultMessage: "Avg New Stayers",
});
export const PaidStayersText = intl.formatMessage({
  defaultMessage: "Paid Stayers",
});
export const TotalPaidStayerText = intl.formatMessage({
  defaultMessage: "Total Paid Stayer",
});
export const TotalRepeatStayerText = intl.formatMessage({
  defaultMessage: "Total Repeat Stayer",
});
export const TotalRepeatPaidStayerText = intl.formatMessage({
  defaultMessage: "Total Repeat Paid Stayer",
});
export const PageSizeText = (count: number) =>
  intl.formatMessage(
    {
      defaultMessage: "{count} / page",
    },
    { count }
  );
export const PageTotalText = (count: number) =>
  intl.formatMessage(
    {
      description: "eg: Total 1 item / Total 50 items",
      defaultMessage:
        "Total {count} {count, plural, =0 {item} =1 {item} other {items}}",
    },
    { count }
  );

// placeholder
export const InputText = intl.formatMessage({
  defaultMessage: "Input",
});
export const SelectText = intl.formatMessage({
  defaultMessage: "Select",
});
export const InputTextText = intl.formatMessage({
  defaultMessage: "Input text",
});
export const PlaceholderRejectionText = intl.formatMessage({
  defaultMessage: "Add a reason about this rejection",
});
export const InputEmailAddressText = intl.formatMessage({
  defaultMessage: "Input email address",
});
export const UsernameText = intl.formatMessage({ defaultMessage: "Username" });
export const PasswordText = intl.formatMessage({ defaultMessage: "Password" });
export const DefaultSelectPlaceholder = intl.formatMessage({
  defaultMessage: "Please Select",
});
export const SelectTypeText = intl.formatMessage({
  defaultMessage: "Select type",
});
export const SelectSpaceText = intl.formatMessage({
  defaultMessage: "Select space",
});
export const SelectInventoryText = intl.formatMessage({
  defaultMessage: "Select inventory",
});
export const SelectDateText = intl.formatMessage({
  defaultMessage: "Select date",
});
export const SelectTimeText = intl.formatMessage({
  defaultMessage: "Select time",
});
export const SelectCityText = intl.formatMessage({
  defaultMessage: "Select City",
});
export const SelectCountryText = intl.formatMessage({
  defaultMessage: "Select Country",
});
export const SelectStartTimeText = intl.formatMessage({
  defaultMessage: "Select start time",
});
export const SelectEndTimeText = intl.formatMessage({
  defaultMessage: "Select end time",
});
export const SelectNumberText = intl.formatMessage({
  defaultMessage: "Select number",
});
export const InputNumberText = intl.formatMessage({
  defaultMessage: "Input number",
});
export const EXUICBuildingCarparkText = intl.formatMessage({
  description: "Carpark name",
  defaultMessage: "Ex: UIC Building Carpark",
});
export const EXAddress = intl.formatMessage({
  description: "Address",
  defaultMessage: "Ex: 5 Shenton Way, UIC Building, Basement 1",
});
export const EXMapAddress = intl.formatMessage({
  description: "Address",
  defaultMessage: "Ex: 10 Collyer Quay",
});
export const EXMapAddressLine2 = intl.formatMessage({
  description: "Address Line 2",
  defaultMessage: "Ex: #28-01",
});
export const EXPhoneNumber = intl.formatMessage({
  description: "phone number",
  defaultMessage: "Ex: +65 88888888",
});
export const RejectTitle = intl.formatMessage({
  defaultMessage: "Are you sure you want to reject this booking request?",
});
export const NotAvailableAtThisTime = intl.formatMessage({
  defaultMessage: "Not available at this time",
});
export const TemporarilyClosed = intl.formatMessage({
  defaultMessage: "Temporarily closed",
});

// action
export const DiscardText = intl.formatMessage({ defaultMessage: "Discard" });
export const MoreText = intl.formatMessage({ defaultMessage: "More" });
export const EditText = intl.formatMessage({ defaultMessage: "Edit" });
export const AddText = intl.formatMessage({ defaultMessage: "Add" });
export const ConfigText = intl.formatMessage({ defaultMessage: "Config" });
export const ConfirmText = intl.formatMessage({ defaultMessage: "Confirm" });
export const ArchiveText = intl.formatMessage({ defaultMessage: "Archive" });
export const ActivateText = intl.formatMessage({ defaultMessage: "Activate" });
export const ConfirmedText = intl.formatMessage({
  defaultMessage: "Confirmed",
});
export const RejectedText = intl.formatMessage({
  defaultMessage: "Rejected",
});
export const RejectText = intl.formatMessage({ defaultMessage: "Reject" });
export const AddInventoryText = intl.formatMessage({
  defaultMessage: "Add Inventory",
});
export const AddCarparkText = intl.formatMessage({
  defaultMessage: "Add carpark",
});
export const EditInventoryText = intl.formatMessage({
  defaultMessage: "Edit Inventory",
});
export const AddSpaceText = intl.formatMessage({ defaultMessage: "Add Space" });
export const EditSpaceText = intl.formatMessage({
  defaultMessage: "Edit Space",
});
export const AddLocationText = intl.formatMessage({
  defaultMessage: "Add Location",
});
export const EditLocationText = intl.formatMessage({
  defaultMessage: "Edit Location",
});
export const AddScannerText = intl.formatMessage({
  defaultMessage: "Add Scanner",
});
export const EditScannerText = intl.formatMessage({
  defaultMessage: "Edit Scanner",
});
export const SubmitText = intl.formatMessage({ defaultMessage: "Submit" });
export const YesText = intl.formatMessage({ defaultMessage: "Yes" });
export const NoText = intl.formatMessage({ defaultMessage: "No" });
export const LoginText = intl.formatMessage({ defaultMessage: "Log In" });
export const CancelText = intl.formatMessage({ defaultMessage: "Cancel" });
export const SaveText = intl.formatMessage({ defaultMessage: "Save" });
export const OKText = intl.formatMessage({ defaultMessage: "OK" });
export const BackText = intl.formatMessage({ defaultMessage: "Back" });
export const DeleteText = intl.formatMessage({ defaultMessage: "Delete" });
export const ResetText = intl.formatMessage({ defaultMessage: "Reset" });
export const ApplyText = intl.formatMessage({ defaultMessage: "Apply" });
export const DownloadText = intl.formatMessage({ defaultMessage: "Download" });
export const SelectAllText = intl.formatMessage({
  defaultMessage: "select all",
});
export const DuplicateText = intl.formatMessage({
  defaultMessage: "Duplicate",
});
export const CancelBookingText = intl.formatMessage({
  defaultMessage: "Cancel Booking",
});
export const BookText = intl.formatMessage({
  defaultMessage: "Book",
  description: "book a room",
});
export const UpdateBookingText = intl.formatMessage({
  defaultMessage: "Update Booking",
});

export const AddRecipientText = intl.formatMessage({
  defaultMessage: "Add recipient",
});

export const RecipientText = intl.formatMessage({
  defaultMessage: "Booking notification recipient",
});

export const PleaseInputEmailText = intl.formatMessage({
  defaultMessage: "Please input email",
});

export const HelpText = intl.formatMessage({
  defaultMessage: "Help",
});

export const LoginNoLocationTipText = intl.formatMessage({
  defaultMessage: "Want to list your location?",
});

export const SignUpText = intl.formatMessage({ defaultMessage: "Sign up" });

export const WifiTypeText = intl.formatMessage({
  defaultMessage: "WiFi Type",
});

export const KeyCardTypeText = intl.formatMessage({
  defaultMessage: "Key Card Type",
});

export const KeyCardText = intl.formatMessage({
  defaultMessage: "Key Card",
});
export const PleaseChooseWifiTypeText = intl.formatMessage({
  defaultMessage: "Please choose WiFi type",
});

export const ChooseWiFiTypeText = intl.formatMessage({
  defaultMessage: "Choose WiFi Type",
});

export const UneditableNoticeText = intl.formatMessage({
  defaultMessage: "uneditable once created.",
});

export const NowText = intl.formatMessage({
  defaultMessage: "Now",
});

export const TableUserNameText = intl.formatMessage({
  defaultMessage: "User Name",
});

export const UsageText = intl.formatMessage({ defaultMessage: "Usage" });
