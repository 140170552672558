import { createContainer } from "unstated-next";
import useStateValue from "hooks/useStateValue";

const container = createContainer(() => {
  const [upcoming, setUpcoming] = useStateValue<number>(0);
  const [ongoing, setOngoing] = useStateValue<number>(0);
  const [pending, setPending] = useStateValue<number>(0);
  return { upcoming, setUpcoming, ongoing, setOngoing, pending, setPending };
});

export const BookingCountsProvider = container.Provider;
export const useBookingCounts = container.useContainer;
export default container;
