import ReactDom from "react-dom";

export class SiblingUtil {
  isShowing = false;
  isWaiting = false;
  private container: any;
  constructor(container = "#sibling") {
    this.container = document.querySelector(container);
  }
  show = async (content: JSX.Element, defaultDelay?: number) => {
    if (this.isShowing) await this.dismiss();
    this.isShowing = true;
    ReactDom.render(content, this.container);
    if (defaultDelay) {
      setTimeout(() => this.dismiss(), defaultDelay);
    }
  };
  dismiss = async () => {
    if (this.isWaiting) return;
    this.isWaiting = true;
    const result = await ReactDom.unmountComponentAtNode(this.container);
    this.isWaiting = false;
    this.isShowing = !result;
    if (!result) this.dismiss();
  };
}

const Siblings = new SiblingUtil();

export default Siblings;
