import { useState, useEffect, useMemo, useCallback } from "react";
import { createContainer } from "unstated-next";

import { useAccessToken } from "./accessToken";
import { getAllSpaces } from "service/v1/scanners";
import useManualFetcher from "hooks/useManualFetcher";
// import { formatError } from "hooks/useError";
import { toObject } from "utils/index";

const container = createContainer(() => {
  const { accessToken, curCountry } = useAccessToken();
  const [spaces, setSpaces] = useState<ISpace[]>([]);
  const [shouldReload, setShouldReload] = useState(false);
  const reloadSpace = useCallback(() => setShouldReload((v) => !v), []);
  const spaceObj = useMemo(() => toObject(spaces), [spaces]);
  const { isLoading, doFetch } = useManualFetcher(getAllSpaces);
  useEffect(() => {
    const service = async () => {
      if (!curCountry?.code) return;
      try {
        const resp = await doFetch(curCountry?.code);
        if (resp && Array.isArray(resp)) setSpaces(resp);
      } catch (e) {
        // formatError(e, "");
        console.log("Get spaces err: ", (e as any)?.message || "service error");
      }
    };
    if (accessToken && accessToken.accessToken) service();
  }, [doFetch, accessToken, curCountry?.code, shouldReload]);
  return { isLoading, spaces, spaceObj, reloadSpace };
});

export const SpaceProvider = container.Provider;
export const useSpaceLoader = container.useContainer;
export default container;
