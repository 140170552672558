import { useMemo } from "react";
import { useWindowSize } from "react-use";
import { testMobile } from "utils/os";

export function useMobile(minWidth = 800) {
  const { width } = useWindowSize();
  const isMobile = useMemo(() => {
    if (width < minWidth) return true;
    return testMobile();
  }, [minWidth, width]);
  return { isMobile, width };
}
