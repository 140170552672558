import { useState, useCallback, useEffect, useMemo } from "react";
import { createContainer } from "unstated-next";

import * as apiAccessToken from "service/request/accessToken";
import { token } from "service/v1/identity";
import tokenKey from "service/request/tokenKey";
import useStateValue from "hooks/useStateValue";

const storedToken = tokenKey ? localStorage.getItem(tokenKey()) : undefined;
const defaultToken = storedToken ? JSON.parse(storedToken) : null;

export enum Authorities {
  // dashboard
  ReadDashboard = "read:dashboard",
  // space inventory
  ReadSpaces = "read:space",
  AddSpace = "add:space",
  EditSpace = "edit:space",
  DeleteSpace = "delete:space",
  EditSpaceListStatus = "edit:space-listing-status",
  ReadInventory = "read:inventory",
  AddInventory = "add:inventory",
  EditInventory = "edit:inventory",
  DeleteInventory = "delete:inventory",
  EditInventoryListStatus = "edit:inventory-listing-status",
  ReadSpaceConfig = "read:space-config",
  AddSpaceConfig = "create:space-config",
  EditSpaceConfig = "edit:space-config",
  // walk in
  ReadWalkIn = "read:walk-in",
  DownloadWalkIn = "download:walk-in",
  // booking
  ReadBooking = "read:booking",
  DownLoadBooking = "download:booking",
  ReadManageBooking = "read:manage-booking",
  EditManageBooking = "edit:manage-booking",
  // role management
  ReadRole = "read:role",
  AddRole = "add:role",
  EditRole = "edit:role",
  DeleteRole = "delete:role",
  // admin user management
  ReadAdmin = "read:user",
  AddAdmin = "add:user",
  EditAdmin = "edit:user",
  ArchiveAdmin = "archive:user",
  // invoice
  ReadInvoice = "read:sp-invoice",
  DownloadInvoice = "download:sp-invoice",
  ReadRevenueReport = "read:revenue-report",
  DownloadRevenueReport = "download:revenue-report",

  ReadUserRatingData = "read:user-rating-data",
  EditUserRatingData = "edit:user-rating-data", // set valid or invalid
  DownloadUserRatingData = "download:user-rating-data",
}

/** @description dev setting for Auth */

const LocalPermission: Authorities[] = [];

const container = createContainer(() => {
  const [accessToken, setAccessToken] = useState<IAccessToken | null>(
    defaultToken
  );
  // currently only overview inventory auth
  const [inventoryAuth, setInventoryAuth] = useState<Array<TInventoryType>>([]);
  const [curCountry, setCurCountry] = useStateValue<IUserCountry | null>(null);
  const [userCountries, setUserCountries] = useStateValue<
    Array<IUserCountries>
  >([]);

  const testAuth = useCallback(
    (key: Authorities) => {
      if (LocalPermission.findIndex((i) => i === key) > -1) return true;
      if (!(accessToken && accessToken.authorities)) return false;
      return accessToken.authorities.findIndex((i) => i === key) > -1;
    },
    [accessToken]
  );
  const testAuthAll = useCallback(
    (keys: Authorities[]) => {
      return keys.every((key) => testAuth(key));
    },
    [testAuth]
  );
  const testAuthSome = useCallback(
    (keys: Authorities[]) => {
      if (!keys.length) return true;
      return keys.some((key) => testAuth(key));
    },
    [testAuth]
  );
  const testInventoryAuth = useCallback(
    (v: TInventoryType) => {
      return inventoryAuth.findIndex((auth) => auth === v) > -1;
    },
    [inventoryAuth]
  );
  const onSetInventoryAuth = useCallback((v) => setInventoryAuth(v), []);
  const clearToken = useCallback(() => {
    apiAccessToken.clearToken();
    setAccessToken(null);
  }, []);
  const setToken = useCallback((token: IAccessToken) => {
    apiAccessToken.setToken(token);
    setAccessToken(token);
  }, []);
  const refreshTokenGenerator = useCallback(async () => {
    const lastToken = apiAccessToken.getToken();
    try {
      const tokenValue = await token();
      setAccessToken(tokenValue as IAccessToken);
      return tokenValue;
    } catch (e) {
      // 修复登录好几次才能登录的bug
      const curToken = apiAccessToken.getToken();
      if (lastToken === curToken) {
        clearToken();
      }
    }
  }, [setAccessToken, clearToken]);

  // const updateCountryByData = useCallback(
  //   (cityCode) => {
  //     if (cityCode !== curCountry?.code && !!userCountries) {
  //       // let target = null;
  //       for (const i of userCountries) {
  //         if (!i.subdivisions) break;
  //         for (const j of i.subdivisions) {
  //           if (j.code === cityCode) {
  //             setCurCountry(j);
  //             // target = j;
  //             break;
  //           }
  //         }
  //       }
  //       // if (!target) window.location.href = "/";
  //     }
  //   },
  //   [curCountry?.code, setCurCountry, userCountries]
  // );

  useEffect(() => {
    apiAccessToken.configureTokenRefresh(refreshTokenGenerator);
    return () => {
      apiAccessToken.configureTokenRefresh(undefined);
    };
  }, [refreshTokenGenerator]);

  return {
    isLogin: accessToken !== undefined && accessToken !== null,
    isInitialized: accessToken !== undefined,
    accessToken,
    clearToken,
    setToken,
    testAuth,
    testAuthAll,
    testAuthSome,
    testInventoryAuth,
    onSetInventoryAuth,
    curCountry,
    setCurCountry,
    userCountries,
    setUserCountries,
    // updateCountryByData,
  };
});

export const getToken = () => apiAccessToken.getToken();

export const AccessTokenProvider = container.Provider;

export const useAccessToken = container.useContainer;

export default container;

export function useCheckAuth(authority: Authorities) {
  const { testAuth } = useAccessToken();
  const hasAuth = useMemo(() => testAuth(authority), [testAuth, authority]);
  return hasAuth;
}
