import styled from "styled-components";
import * as C from "styles/colors";
import { ReactComponent as LangSVG } from "images/icon-lang.svg";
import { ReactComponent as DropSVG } from "images/icon-dropdown-arrow.svg";

export const LangIcon = styled(LangSVG)`
  width: 16px;
  height: 16px;
  fill: currentColor;
`;

export const DropIcon = styled(DropSVG)<{ $active?: boolean }>`
  width: 12px;
  height: 12px;
  fill: currentColor;
  transform: ${({ $active }) => ($active ? `rotate(-180deg)` : `rotate(0)`)};
  transition: all 0.2s ease;
`;

export const TriggerText = styled.span``;

export const Trigger = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${C.Black59};
  cursor: pointer;
  display: flex;
  align-items: center;

  ${TriggerText} {
    margin: 0 6px;
  }
`;

export const LangList = styled.div``;

export const LangItem = styled.a`
  display: block;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  white-space: nowrap;
  cursor: pointer;
  color: ${C.Black};
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &:last-child {
    margin-bottom: 8px;
  }

  &:first-child {
    margin-top: 8px;
  }

  &:hover {
    background: ${C.GrayFA};
    color: inherit;
  }
`;
