import intl from "langs/intl";
import React from "react";

// Inventory
export const InventoryAddedText = intl.formatMessage({
  defaultMessage: "Inventory Added",
});
export const InventoryUpdatedText = intl.formatMessage({
  defaultMessage: "Inventory Updated",
});
export const InventoryDuplicatedText = intl.formatMessage({
  defaultMessage: "Inventory Duplicated",
});
export const InventoryBookedText = intl.formatMessage({
  defaultMessage: "Inventory Booked",
});
export const InventoryDeletedText = intl.formatMessage({
  defaultMessage: "Inventory Deleted",
});
export const SubmitInventoryErrText = intl.formatMessage({
  defaultMessage: "Submit inventory data error.",
});
export const LoadInventoryErrText = intl.formatMessage({
  defaultMessage: "Load inventory error!",
});
export const UpdateInventoryErrText = intl.formatMessage({
  defaultMessage: "Update inventory error.",
});
export const DeleteInventoryText = intl.formatMessage({
  defaultMessage: "Are you sure you want to delete this inventory?",
});
export const DeleteTipText = intl.formatMessage({
  defaultMessage: "Once deleted, all data will be removed permanently.",
});
export const DiscardChangesText = intl.formatMessage({
  defaultMessage: "You have made some changes. Do you want to discard them?",
});
export const InventoryOpeningHourTipText = intl.formatMessage({
  defaultMessage:
    "Inventory opening hour cannot be longer than space opening hour.",
});
export const ArchiveConfirmTitleText = intl.formatMessage({
  defaultMessage: "Are you sure you want to archive this item?",
});
export const ActivateConfirmTitleText = intl.formatMessage({
  defaultMessage: "Are you sure to reactivate this account?",
});

// Booking
export const BookingErrorText = intl.formatMessage({
  defaultMessage: "Booking Error",
});
export const BookingCancelledText = intl.formatMessage({
  defaultMessage: "Booking Cancelled",
});
export const CancellBookingConfirmText = intl.formatMessage({
  defaultMessage: "Are you sure you want to cancel this booking?",
});
export const NoBookableWorkspaceText = intl.formatMessage({
  defaultMessage: "There is no bookable workspace.",
});
export const YesCancelText = intl.formatMessage({
  defaultMessage: "Yes, Cancel",
});
export const NotNowText = intl.formatMessage({
  defaultMessage: "Not Now",
});

// form
export const InputUsernameText = intl.formatMessage({
  defaultMessage: "Please input your username",
});
export const InputPasswordText = intl.formatMessage({
  defaultMessage: "Please input your password",
});
export const ChangesSavedText = intl.formatMessage({
  defaultMessage: "Changes Saved",
});
export const SavedText = intl.formatMessage({
  defaultMessage: "Saved",
});
export const DeleteErrorText = intl.formatMessage({
  defaultMessage: "Delete error",
});
export const DeleteSuccessText = intl.formatMessage({
  defaultMessage: "Delete success",
});
export const UploadImgErrText = intl.formatMessage({
  defaultMessage: "Upload error",
});
export const UploadRequireText = intl.formatMessage({
  defaultMessage:
    "Upload at least 1 and up to 10 images. File size cannot exceed 800KB.",
});
export const UploadImgOverLimitText = intl.formatMessage({
  defaultMessage: "Sorry, you cannot upload photos larger than 800KB",
});
export const UnfinushedErrText = intl.formatMessage({
  defaultMessage: "There is uncompleted upload.",
});
export const FailToLoadImageText = intl.formatMessage({
  defaultMessage: "Fail to load image.",
});

// space
export const LoadSpaceErrText = intl.formatMessage({
  defaultMessage: "Load Space error!",
});
export const UpdateSpaceErrText = intl.formatMessage({
  defaultMessage: "Update space error.",
});
export const SpaceDeletedText = intl.formatMessage({
  defaultMessage: "Space Deleted",
});
export const DeleteSpaceText = intl.formatMessage({
  defaultMessage: "Are you sure you want to delete this space?",
});
export const DeleteScannerText = intl.formatMessage({
  defaultMessage: "Are you sure you want to delete this scanner?",
});
export const SubmitSpaceErrText = intl.formatMessage({
  defaultMessage: "Submit space data error.",
});
export const SpaceAddedText = intl.formatMessage({
  defaultMessage: "Space Added",
});
export const SpaceDuplicatedText = intl.formatMessage({
  defaultMessage: "Space Duplicated",
});
export const SpacePublishLimitText = intl.formatMessage({
  defaultMessage:
    "A space can only be published when there is at least 1 inventory.",
});

// scanner
export const AlreadyConfigedText = intl.formatMessage({
  defaultMessage: "Already Configed",
});
export const SubmitSuccessText = intl.formatMessage({
  defaultMessage: "Submit success",
});
export const AddSuccessText = intl.formatMessage({
  defaultMessage: "Add success",
});
export const BindSuccessText = intl.formatMessage({
  defaultMessage: "Bind success",
});
export const BindErrorText = intl.formatMessage({
  defaultMessage: "Bind error",
});
export const ConfirmConfigText = intl.formatMessage({
  defaultMessage: "Confirm Config",
});
export const NoConfigurableQRCodeText = intl.formatMessage({
  defaultMessage: "No Configurable QR Code",
});
export const RequestQRCodeErrorText = intl.formatMessage({
  defaultMessage: "Request QR Code Error.",
});

// others
export const LoadAmenitiesErrText = intl.formatMessage({
  defaultMessage: "Load amenities error.",
});
export const RequestIsSubmittingText = intl.formatMessage({
  defaultMessage: "Request is submitting",
});
export const UnknownErrorText = intl.formatMessage({
  defaultMessage: "Unknown error",
});
export const RequestDataFailedText = intl.formatMessage({
  defaultMessage: "Request data failed",
});
export const PleaseUseDesktopWebsiteText = intl.formatMessage({
  defaultMessage: "Please use desktop website.",
});
export const SpecialCharactersText = intl.formatMessage({
  defaultMessage: "Please do not input special characters.",
});
export const CanNotBeEmptyText = intl.formatMessage({
  defaultMessage: "Can not be empty.",
});
export const PleaseSelectTimeText = intl.formatMessage({
  defaultMessage: "Please select time.",
});
export const MinLengthText = (value: number, unit: string) =>
  intl.formatMessage(
    {
      description: "input err",
      defaultMessage: "Please input more than {value} {unit}.",
    },
    {
      value,
      unit,
    }
  );
export const QRBrightnessTipText = intl.formatMessage(
  {
    defaultMessage:
      "Please use the full brightness of the device {newLine} if there has some trouble with scanning",
  },
  {
    newLine: <br />,
  }
);
export const StartAddingSpaceTipText = intl.formatMessage(
  {
    defaultMessage: "There’s no data yet. {newLine} Start adding location now.",
  },
  {
    newLine: <br />,
  }
);
export const StartAddingSpaceCodeTipText = intl.formatMessage(
  {
    defaultMessage:
      "There’s no data yet. {newLine} Start adding space to this SFB now.",
  },
  {
    newLine: <br />,
  }
);
export const StartAddingInventoryTipText = intl.formatMessage(
  {
    defaultMessage:
      "There’s no data yet. {newLine} Start adding inventory to this space now.",
  },
  {
    newLine: <br />,
  }
);
export const ThereIsNoDataYetText = intl.formatMessage({
  defaultMessage: "There’s no data yet.",
});

export const MobileLoginMessage = (link: any) =>
  intl.formatMessage(
    {
      defaultMessage:
        "It looks like you’re viewing this application on a mobile device. To get the best experience, please visit {link} on a laptop computer. ",
    },
    { link }
  );

export const GotItText = intl.formatMessage({
  defaultMessage: "GOT IT",
});
