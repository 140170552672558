import styled from "styled-components";

import { FlexRow, FlexCenter } from "styles/common";
import * as C from "styles/colors";

export const Title = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  font-weight: bold;
  color: ${C.Black};
`;

export const Tip = styled.p`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${C.Neutral7};
  letter-spacing: 0.015em;
  margin-top: 8px;
`;

export const Actions = styled.div`
  ${FlexRow}
  ${FlexCenter}
  margin-top: 24px;
`;

export const Wrapper = styled.div`
  padding: 32px 24px;
`;
