import React, { useMemo, memo, useCallback, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useUpdateEffect } from "react-use";

import { LoadingIcon } from "components/Loading";
import SelectLanguage from "components/SelectLanguage";
import TextButton from "@REInventVenturesPteLtd/sui/lib/components/Buttons/TextButton";

import * as S from "./style.PortalLayout";
import { ReactComponent as Logo } from "images/logo-text.svg";
import { CaptionText } from "styles/fonts";
import { PrimaryCream } from "styles/colors";
import { IconHelp } from "styles/icons";

import { websiteUrl } from "service/request/baseUrl";
import { useAccessToken } from "store/accessToken";
import { useBookingCounts } from "store/useBookingCounts";
import { LeftSiderConfig, SiteNav } from "constants/navigators";
import { Theme } from "constants/theme";
import { SignOutButton } from "./SignOutButton";

import {
  useTrigger,
  useDetectDropdownPosition,
} from "hooks/useDetectOutComponent";
import { formText } from "langs/formatText";

function PortalLayout({
  children,
  contentWidth,
  minContentWidth,
  isViewHeight,
  contentStyle,
  minHeight,
}: {
  children: React.ReactNode;
  contentWidth?: number | string;
  minContentWidth?: number;
  isViewHeight?: boolean;
  contentStyle?: React.CSSProperties;
  minHeight?: number;
}) {
  return (
    <S.Layout>
      <LeftSlider />
      <S.RightContent
        style={{ minHeight, overflowY: minHeight ? "hidden" : "auto" }}
      >
        <Header />
        <S.Content style={contentStyle}>
          {contentWidth ? (
            <S.InnerContent
              style={{
                width: contentWidth,
                minWidth: minContentWidth || "unset",
                overflowX: "hidden",
                overflowY: isViewHeight ? "hidden" : "unset",
              }}
            >
              {children}
            </S.InnerContent>
          ) : (
            children
          )}
        </S.Content>
      </S.RightContent>
    </S.Layout>
  );
}

const LeftSlider = memo(() => {
  const {
    testAuthAll,
    testAuthSome,
    curCountry,
    accessToken,
  } = useAccessToken();
  const { path } = useRouteMatch();
  const defaultPath = useMemo(() => {
    const url = path.replace(/\//g, "");
    return url ? [url] : [LeftSiderConfig[0].key];
  }, [path]);
  const selectedKeys = useMemo(() => {
    const keys = path.split("/");
    return [keys[1]] || [];
  }, [path]);
  const defaultOpenKeys = useMemo(() => {
    const keys = path.split("/");
    if (keys[1] === SiteNav.USAGE) return [SiteNav.WALK_INS];
    if (keys[1] === SiteNav.BOOKINGS || keys[1] === SiteNav.BOOKINGS_USAGE)
      return [SiteNav.SUB_BOOKING];
    return [];
  }, [path]);
  const [openKeys, setOpenKeys] = useState<SiteNav[]>(defaultOpenKeys);
  const showCountry = useMemo(
    () => openKeys.findIndex((v) => v === SiteNav.COUNTRY) > -1,
    [openKeys]
  );
  const closeCountry = useCallback(() => {
    setOpenKeys((v) => v.filter((key) => key !== SiteNav.COUNTRY));
  }, []);
  const { open, onIn, onOut, toggleSelections } = useTrigger(
    false,
    closeCountry
  );
  useUpdateEffect(() => {
    toggleSelections();
  }, [showCountry, toggleSelections]);
  const { top, left, updateRect } = useDetectDropdownPosition();
  const onCountryMouseEnter = useCallback(
    (e) => {
      if (!e?.domEvent) return;
      onIn();
    },
    [onIn]
  );
  const onCountryMouseLeave = useCallback(
    (e) => {
      if (!e?.domEvent) return;
      onOut();
    },
    [onOut]
  );
  const onTitleClick = useCallback(
    // update openKeys
    (e) => {
      if (!e) return;
      if (e.key === SiteNav.COUNTRY && !curCountry?.code) return;
      const index = openKeys.findIndex((v) => v === e.key);
      if (index > -1) {
        const newV = [...openKeys];
        newV.splice(index, 1);
        setOpenKeys(newV);
        return;
      }
      if (e.key === SiteNav.COUNTRY) {
        updateRect(
          (e.domEvent.target as HTMLDivElement).getBoundingClientRect()
        );
      }
      setOpenKeys((v: SiteNav[]) => [...v, e.key]);
    },
    [openKeys, updateRect, curCountry]
  );
  const onClickRegionClose = useCallback(() => {
    const index = openKeys.findIndex((v) => v === SiteNav.COUNTRY);
    const newV = [...openKeys];
    newV.splice(index, 1);
    setOpenKeys(newV);
  }, [openKeys]);
  return (
    <S.LeftSider
      theme={Theme}
      className="admin-left-slider"
      width={S.LeftSiderWidth}
    >
      <S.LogoWrapper>
        <S.Logo>
          <Logo title="work anywhere" />
        </S.Logo>
        <CaptionText style={{ fontSize: 10, color: "#8C8C8C" }}>
          {formText.SpaceProviderPortalText}
        </CaptionText>
      </S.LogoWrapper>
      <S.LeftWrapper
        theme={Theme}
        mode="inline"
        defaultSelectedKeys={defaultPath}
        selectedKeys={selectedKeys}
        openKeys={openKeys}
      >
        <S.LeftSliderSubMenu
          title={curCountry?.name || <LoadingIcon />}
          onTitleClick={onTitleClick}
          key={SiteNav.COUNTRY}
          onTitleMouseEnter={onCountryMouseEnter}
          onTitleMouseLeave={onCountryMouseLeave}
          hasbottomline="true"
        />
        {LeftSiderConfig.map((c) => {
          if (!testAuthSome(c.needSome || [])) return null;
          if (!testAuthAll(c.need || [])) return null;
          if (c.subItems) {
            return (
              <S.LeftSliderSubMenu
                title={c.title}
                key={c.key}
                onTitleClick={onTitleClick}
              >
                {c.subItems.map((subC) => {
                  if (!testAuthSome(subC.needSome || [])) return null;
                  if (!testAuthAll(subC.need || [])) return null;
                  return (
                    <SideMenuItem
                      key={subC.key}
                      to={subC.to}
                      title={subC.title}
                      issub="true"
                    />
                  );
                })}
              </S.LeftSliderSubMenu>
            );
          }
          return <SideMenuItem key={c.key} to={c.to} title={c.title} />;
        })}
      </S.LeftWrapper>
      <S.Blank />
      {!!accessToken?.brandInPortal && (
        <S.BrandLabel>{accessToken.brandInPortal}</S.BrandLabel>
      )}
      <CountryPopup
        open={open}
        style={{ top, left: left + 16 }}
        onWrapperEnter={onIn}
        onWrapperLeave={onOut}
        onClose={onClickRegionClose}
      />
    </S.LeftSider>
  );
});

interface CountryPopupProps {
  open: boolean;
  style?: React.CSSProperties;
  onWrapperEnter: () => void;
  onWrapperLeave: () => void;
  onClose: () => void;
}

const CountryPopup = memo(
  ({
    open,
    style,
    onWrapperEnter,
    onWrapperLeave,
    onClose,
  }: CountryPopupProps) => {
    const { curCountry, userCountries, setCurCountry } = useAccessToken();
    const onItemClick = useCallback(
      (cur: IUserCountry) => {
        setCurCountry(cur);
        onClose();
      },
      [onClose, setCurCountry]
    );
    return (
      <S.CountryWrapper
        isShow={open}
        style={style}
        onMouseEnter={onWrapperEnter}
        onMouseLeave={onWrapperLeave}
      >
        <S.CountryTitle isShow={open}>{formText.SelectCityText}</S.CountryTitle>
        <S.CountryScrollContainer isShow={open}>
          {userCountries.map((country) => (
            <React.Fragment key={country.countryCode + "wrapper"}>
              <S.OptionItem
                key={country.countryCode}
                isSub={true}
                isShow={open}
              >
                {country.name}
              </S.OptionItem>
              {country.subdivisions.map((region) => (
                <S.OptionItem
                  key={region.code}
                  isShow={open}
                  onClick={onItemClick.bind(null, region)}
                >
                  {region.name}
                  {curCountry?.code === region.code && <S.IconSelected />}
                </S.OptionItem>
              ))}
            </React.Fragment>
          ))}
        </S.CountryScrollContainer>
      </S.CountryWrapper>
    );
  }
);

interface SideMenuItemProps {
  to?: string;
  title: string;
  issub?: string;
}

const SideMenuItem = memo(({ to, title, ...antdProps }: SideMenuItemProps) => {
  const { ongoing, upcoming, pending } = useBookingCounts();
  const counts = useMemo(() => {
    if (to === `/${SiteNav.BOOKINGS_USAGE}`)
      return (ongoing || 0) + (upcoming || 0) + (pending || 0);
    return undefined;
  }, [to, ongoing, upcoming, pending]);
  return (
    <S.LeftSliderItem {...antdProps}>
      {/* // const Icon = (S as any)[c.icon || "IconBook"]; */}
      {/* <Icon isact={path === c.to ? "true" : ""} /> */}
      {to ? (
        <Link to={to} draggable={false}>
          {title}
          {!!counts && <S.CountBadge>{counts}</S.CountBadge>}
        </Link>
      ) : (
        title
      )}
    </S.LeftSliderItem>
  );
});

const Header = React.memo(() => {
  const jumpToHelp = useCallback(() => {
    window.open(`${websiteUrl()}/help`, "_blank");
  }, []);

  return (
    <S.Header style={{ backgroundColor: PrimaryCream }}>
      <TextButton
        prefixEl={<IconHelp />}
        onClick={jumpToHelp}
        style={{ marginRight: 16 }}
      >
        {formText.HelpText}
      </TextButton>
      <SelectLanguage />
      <S.HeaderSplit />
      <SignOutButton />
    </S.Header>
  );
});

export default PortalLayout;
