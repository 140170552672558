import styled from "styled-components";
import { Neutral8, Neutral7, Neutral13 } from "styles/colors";

import { ReactComponent as PlusSVG } from "images/icon-plus.svg";
import { ReactComponent as DeleteSVG } from "images/icon-delete.svg";
import { ReactComponent as UploadSVG } from "images/icon-upload.svg";
import { ReactComponent as UploadFailedSVG } from "images/icon-upload-failed.svg";
import { ReactComponent as TurnLeftSVG } from "images/icon-turn-left.svg";
import { ReactComponent as TurnRightSVG } from "images/icon-turn-right.svg";
import { ReactComponent as ArrowDownSVG } from "images/icon-arrow-down-btn.svg";
import { ReactComponent as MoreSVG } from "images/icon-more.svg";
import { ReactComponent as ArrowUpSVG } from "images/icon-arrow-up.svg";
import { ReactComponent as SearchSVG } from "images/icon-search.svg";
import { ReactComponent as ArchiveSVG } from "images/icon-archive.svg";
import { ReactComponent as CloseSVG } from "images/icon-close.svg";
import { ReactComponent as HelpSVG } from "images/icon-help.svg";
import { ReactComponent as LogoSVG } from "images/logo-text.svg";
import { ReactComponent as DuplicateSVG } from "images/icon-duplicate.svg";
import { ReactComponent as CheckSVG } from "images/icon-check.svg";
import { ReactComponent as IconClearAllSVG } from "images/icon-clear-all.svg";
import { ReactComponent as IconDownloadSVG } from "images/icon-download.svg";
import { ReactComponent as IconExportSVG } from "images/icon-download-csv.svg";
import { ReactComponent as IconPhoneSVG } from "images/icon-phone.svg";
import { ReactComponent as IconPaymentSVG } from "images/icon-payment.svg";
import { ReactComponent as IconClockSVG } from "images/icon-clock.svg";
import { ReactComponent as IconDurationSVG } from "images/icon-duration.svg";

export const IconPhone = styled(IconPhoneSVG)`
  color: ${Neutral8};
`;
export const IconPayment = styled(IconPaymentSVG)`
  color: ${Neutral8};
`;
export const IconClock = styled(IconClockSVG)`
  color: ${Neutral8};
`;
export const IconDuration = styled(IconDurationSVG)`
  color: ${Neutral8};
`;

export const IconPlus = styled(PlusSVG)``;
export const IconDelete = styled(DeleteSVG)`
  fill: ${Neutral8};
  cursor: pointer;
`;
export const IconDuplicate = styled(DuplicateSVG)`
  fill: ${Neutral8};
  cursor: pointer;
`;
export const IconUpload = styled(UploadSVG)``;
export const IconUploadFailed = styled(UploadFailedSVG)``;
export const IconTurnLeft = styled(TurnLeftSVG)``;
export const IconTurnRight = styled(TurnRightSVG)``;
export const IconArrow = styled(ArrowDownSVG)``;
export const IconArrowDown = styled(ArrowDownSVG)``;
export const IconArrowUp = styled(ArrowUpSVG)``;
export const IconMore = styled(MoreSVG)`
  fill: #000;
  cursor: pointer;
`;
export const IconSearch = styled(SearchSVG)``;
export const IconArchive = styled(ArchiveSVG)``;
export const IconClose = styled(CloseSVG)``;
export const IconHelp = styled(HelpSVG)`
  width: 16px;
  height: 16px;
  color: ${Neutral8};
`;

export const LogoText = styled(LogoSVG)`
  fill: ${Neutral7};
`;
export const IconCheck = styled(CheckSVG)`
  fill: #fff;
`;
export const IconClearAll = styled(IconClearAllSVG)`
  color: ${Neutral8};
  &:hover {
    color: ${Neutral13};
  }
`;
export const IconDownload = styled(IconDownloadSVG)`
  fill: currentColor;
  color: ${Neutral8};
`;
export const IconExport = styled(IconExportSVG)`
  color: ${Neutral8};
`;
