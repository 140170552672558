import { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import type { UnregisterCallback } from "history";
import { BehaviorSubject } from "rxjs";
import { useBehavior } from "utils/@reactive-service/react/src";

import { messageText, formText } from "langs/formatText";
import { showConfirmModal } from "components/Spec/ModalConfirm";
import useCurrent from "hooks/useCurrent";

let beforeUnloadListener: ((args: any) => any) | null = null;
const $unBlocks = new BehaviorSubject<UnregisterCallback[]>([]);
const getUnBlocks = (): UnregisterCallback[] => $unBlocks.value;
const addUnblocks = (fn: UnregisterCallback) => {
  const list = [...getUnBlocks()];
  if (!list.includes(fn)) {
    list.push(fn);
    $unBlocks.next(list);
  }
};
const removeUnBlocks = (fn: UnregisterCallback) => {
  const list = [...getUnBlocks()];
  const index = list.indexOf(fn);
  if (index !== -1) {
    list.splice(index, 1);
    $unBlocks.next(list);
  }
};

export function getUserConfirmation(
  message: string | boolean,
  callback: (ok: boolean) => void
) {
  if (!message) return callback(true);
  showConfirmModal({
    title: message || messageText.DiscardChangesText,
    confirmText: formText.DiscardText,
    onClose: () => {
      callback(false);
    },
    onConfirm: () => {
      callback(true);
    },
  });
}

type HistoryBlockProps = {
  message?: string;
  title?: string;
  confirmText?: string;
  check?: () => boolean;
};
export default function HistoryBlock(props: HistoryBlockProps) {
  const history = useHistory();
  const unblocks = useBehavior($unBlocks);
  const unblockRef = useRef<UnregisterCallback | null>(null);
  const unListenRef = useRef<(() => void) | null>(null);
  const checkRef = useCurrent(props.check);
  const { message, title } = props;

  useEffect(() => {
    // 同一时间只有一个 unblock, 如果有其他的 unblock 存在，则忽略这个
    if (unblocks.length === 0) {
      unblockRef.current = history.block(() => {
        if (checkRef.current && !checkRef.current()) return;
        return message || title || messageText.DiscardChangesText;
      });
      addUnblocks(unblockRef.current);
      // history v5 会监听 beforeunload，v4 需要自己加
      beforeUnloadListener &&
        window.removeEventListener("beforeunload", beforeUnloadListener);
      beforeUnloadListener = (event: BeforeUnloadEvent) => {
        if (checkRef.current && !checkRef.current()) return;
        event.preventDefault();
        return (event.returnValue = "Are you sure you want to exit?");
      };
      window.addEventListener("beforeunload", beforeUnloadListener);
      unListenRef.current = () => {
        beforeUnloadListener &&
          window.removeEventListener("beforeunload", beforeUnloadListener);
        beforeUnloadListener = null;
      };
    }
  }, [history, message, title, unblocks, checkRef]);

  useEffect(() => {
    return () => {
      if (unblockRef.current) {
        removeUnBlocks(unblockRef.current);
        unblockRef.current();
        unblockRef.current = null;
      }
      unListenRef.current && unListenRef.current();
    };
  }, []);

  return null;
}

export function checkHistoryLeave(leave: () => void) {
  const blocks = getUnBlocks();
  if (!blocks.length) return leave();
  showConfirmModal({
    title: messageText.DiscardChangesText,
    confirmText: formText.DiscardText,
    onConfirm: () => {
      blocks.forEach((unblock) => {
        unblock();
      });
      blocks.splice(0, blocks.length);
      beforeUnloadListener &&
        window.removeEventListener("beforeunload", beforeUnloadListener);
      beforeUnloadListener = null;
      leave();
    },
  });
}
