import { BehaviorSubject } from "rxjs";
import queryString from "query-string";
import {
  LangType,
  languages,
  defaultLanguage,
  languagesMap,
  LangSelects,
  momentLangMap,
} from "constants/language";

const normalizeLanguage = (lang: string) => {
  // 精确匹配：en-US => en-US
  const arr = languages.map((item) => item.toLocaleLowerCase());
  const i = arr.indexOf(lang.toLocaleLowerCase());
  if (i !== -1) return languages[i];

  // 映射
  for (let item of languagesMap) {
    if (item[0].test(lang) && languages.includes(item[1])) return item[1];
  }

  // 默认值
  return "";
};

class Language {
  // 当前设置过的语言
  inited = false;
  current: string = defaultLanguage;
  $current: BehaviorSubject<LangType> = new BehaviorSubject(defaultLanguage);
  momentLang: string = momentLangMap[defaultLanguage];

  constructor() {
    this.initIfNeed();
  }

  // 获取path中的语言, 必须精确匹配才能当做语言，否则当成普通路径
  getPathLanguage(location: Partial<Location> = window.location) {
    const path = location.pathname || "";
    const first = path.split("/")[1];
    return first || "";
  }

  // 获取参数中的语言, 必须精确匹配才能当做语言，否则当成普通路径
  getQueryLanguage(location: Partial<Location> = window.location) {
    const query = queryString.parse(location.search || "");
    return (query["lang"] || "") as string;
  }

  // 获取浏览器语言
  getBrowserLanguage() {
    return (
      navigator.language || (window.navigator as any).browserLanguage || ""
    );
  }

  // 获取上一次切换的语言
  getStoredLanguage() {
    const lang = localStorage.getItem("language");
    return lang;
  }

  // get cur lang
  // return {lang, type}
  getLanguage(args?: {
    types?: string[];
    defaultLanguage?: LangType;
    location: Partial<Location>;
  }) {
    let {
      // 目前只取用户上次保存的语言，否则为默认语言
      types = [
        // "path",
        "query",
        "store",
        // "browser",
      ],
      defaultLanguage: def = defaultLanguage,
      location = window.location,
    } = args || {};

    let langs: Record<string, string>,
      lang: string,
      useType = null;

    langs = {
      path: this.getPathLanguage(location) || "",
      query: this.getQueryLanguage(location) || "",
      browser: this.getBrowserLanguage() || "",
      store: this.getStoredLanguage() || "",
    };
    lang = "";
    types.forEach((type) => {
      if (lang) return;
      if (langs[type]) {
        const res = normalizeLanguage(langs[type]);
        if (res) {
          useType = type;
          lang = res;
        }
      }
    });

    return { lang: (lang as LangType) || def, type: useType };
  }

  setCurrent(lang: LangType, store = true) {
    if (!this.isValidLang(lang)) {
      console.error(`error lang ${lang}`);
      return;
    }
    this.current = lang;
    this.momentLang = momentLangMap[lang];
    this.$current.next(lang);
    store && localStorage.setItem("language", lang);
  }

  isAlreadySetted() {
    return !!localStorage.getItem("language");
  }

  isValidLang(lang: string) {
    return (languages as string[]).includes(lang);
  }

  initIfNeed() {
    if (this.inited) return;
    // url 设置语言
    const query = queryString.parse(window.location.search || "");
    const sessionKey = "set_default_lang";
    const setLang = (query["set-lang"] || query["language"] || "") as string;
    if (
      !sessionStorage.getItem(sessionKey) &&
      setLang &&
      (languages as string[]).includes(setLang)
    ) {
      sessionStorage.setItem(sessionKey, setLang);
      this.setCurrent(setLang as LangType);
      this.inited = true;
      return;
    }
    // 其他
    let { lang, type } = this.getLanguage();
    if (!(languages as string[]).includes(lang)) {
      lang = defaultLanguage;
      type = null;
    }
    this.setCurrent(lang, type !== "query");
    this.inited = true;
  }
}

const language = new Language();

export default language;
export { LangType, languages, defaultLanguage, languagesMap, LangSelects };
