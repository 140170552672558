import intl from "langs/intl";

export const MondayText = intl.formatMessage({
  defaultMessage: "Monday",
});
export const TuesdayText = intl.formatMessage({
  defaultMessage: "Tuesday",
});
export const WednesdayText = intl.formatMessage({
  defaultMessage: "Wednesday",
});
export const ThursdayText = intl.formatMessage({
  defaultMessage: "Thursday",
});
export const FridayText = intl.formatMessage({
  defaultMessage: "Friday",
});
export const SaturdayText = intl.formatMessage({
  defaultMessage: "Saturday",
});
export const SundayText = intl.formatMessage({
  defaultMessage: "Sunday",
});
export const DayText = intl.formatMessage({ defaultMessage: "Day" });
export const DaysText = intl.formatMessage({ defaultMessage: "Days" });
export const MinuteText = intl.formatMessage({ defaultMessage: "Minute" });
export const HourText = intl.formatMessage({ defaultMessage: "Hour" });
export const WeekText = intl.formatMessage({ defaultMessage: "Week" });
export const WeeksText = intl.formatMessage({ defaultMessage: "Weeks" });
export const MonthText = intl.formatMessage({ defaultMessage: "Month" });
export const MonthsText = intl.formatMessage({ defaultMessage: "Months" });
export const YearText = intl.formatMessage({ defaultMessage: "Year" });
export const YearsText = intl.formatMessage({ defaultMessage: "Years" });
export const RangeText = intl.formatMessage({ defaultMessage: "Range" });
export const SinceText = intl.formatMessage({ defaultMessage: "Since" });
export const CustomText = intl.formatMessage({ defaultMessage: "Custom" });
export const OtherText = intl.formatMessage({ defaultMessage: "Other" });
export const TodayText = intl.formatMessage({ defaultMessage: "Today" });
export const YesterdayText = intl.formatMessage({
  defaultMessage: "Yesterday",
});
export const ThisWeekText = intl.formatMessage({ defaultMessage: "This Week" });
export const ThisYearText = intl.formatMessage({ defaultMessage: "This Year" });
export const ThisMonthText = intl.formatMessage({
  defaultMessage: "This Month",
});
