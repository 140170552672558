import useSWR from "swr";
import request from "../request";
import { TimeUnit } from "utils/date";
import { getTokenValue } from "../request/accessToken";

export const getSpaceCode = async (
  _url: string,
  page: number,
  size: number,
  sort?: SortType,
  providerId?: number | string
) =>
  request<TSpaceCodeListResp>({
    path: "/admin/v1/space-code",
    method: "GET",
    refreshOnUnauthorized: true,
    data: {
      page,
      size,
      sort: sort ? `createdAt,${sort}` : undefined,
      providerId,
    },
  });

export function useSpaceCodeListLoad(
  page: number,
  size: number,
  sort?: SortType,
  providerId?: number | string
) {
  const accessToken = getTokenValue();
  const { data, mutate, isValidating } = useSWR(
    [`${accessToken}/space-code`, page, size, sort, providerId],
    getSpaceCode,
    { refreshInterval: TimeUnit.Minute * 5 }
  );
  let result: TSpaceCodeItem[] = [];
  let total = 0;
  if (data?.items) result = data.items;
  if (data?.total) total = data.total;
  return { result, mutate, isValidating, total };
}

export const getPartners = async () =>
  request({
    method: "GET",
    path: "/admin/v1/space-code/assign-user",
    refreshOnUnauthorized: true,
  });

export const getSpaceCodeAssignUser = async () =>
  request({
    method: "GET",
    path: "/admin/v1/space-code/assign-space-provider",
    refreshOnUnauthorized: true,
  });

export const getSpaceCodeDetail = async (id: number | string) =>
  request<TSpaceCodeDetail>({
    method: "GET",
    path: `/admin/v1/space-code/${id}`,
    refreshOnUnauthorized: true,
  });

export const updateSpaceCode = async (data: Partial<TSpaceCodeDetail>) =>
  request({
    method: "POST",
    path: "/admin/v1/space-code",
    refreshOnUnauthorized: true,
    data,
  });

export const updateSpaceOtherInfo = async (data: Partial<TSpaceOtherInfo>) =>
  request({
    method: "POST",
    path: "/admin/v1/spaces/space-ext",
    refreshOnUnauthorized: true,
    data,
  });
