import React, { useMemo, useState, useCallback } from "react";

import { checkHistoryLeave } from "components/HistoryBlock";
import { LangSelects, LangType } from "langs/language";
import { useLanguage } from "langs/intl";
import Dropdown from "components/Spec/Dropdown";
import TextButton from "@REInventVenturesPteLtd/sui/lib/components/Buttons/TextButton";
import * as S from "./index.style";

type SelectLanguageProps = {
  style?: React.CSSProperties;
};
export default function SelectLanguage(props: SelectLanguageProps) {
  const [language, setLanguage] = useLanguage();
  const [visible, setVisible] = useState(false);
  const text = useMemo(() => {
    const find = LangSelects.find((item) => item.value === language);
    return find ? find.text : "";
  }, [language]);

  const changeLanguage = useCallback(
    (value: LangType) => {
      setLanguage(value);
      setVisible(false);
      window.location.reload();
    },
    [setLanguage]
  );

  const doCheckLeave = useCallback(
    (value: LangType) => {
      checkHistoryLeave(() => {
        changeLanguage(value);
      });
    },
    [changeLanguage]
  );

  const content = (
    <S.LangList>
      {LangSelects.map((item) => (
        <S.LangItem key={item.value} onClick={() => doCheckLeave(item.value)}>
          {item.text}
        </S.LangItem>
      ))}
    </S.LangList>
  );

  return (
    <Dropdown
      content={content}
      trigger={["click"]}
      visible={visible}
      onVisibleChange={(v) => setVisible(v)}
      overlayStyle={{ zIndex: 999 }}
    >
      <TextButton
        prefixEl={<S.LangIcon />}
        suffixEl={<S.DropIcon $active={visible} />}
        style={props.style}
      >
        {text}
      </TextButton>
    </Dropdown>
  );
}
