import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import type { Location } from "history";
import { config } from "utils/@reactive-service/react/src";

import { IntlProvider } from "langs/intl";
import { findFirstValidRoute } from "routes";
import {
  AccessTokenProvider,
  useAccessToken,
  Authorities,
} from "store/accessToken";
import { SpaceProvider } from "store/useSpacesLoader";
import { PartnersProvider } from "store/usePartnersLoader";
import { PortalStyleProvider } from "store/usePortalStyle";
import {
  BookingCountsProvider,
  useBookingCounts,
} from "store/useBookingCounts";
import { notificationErr } from "hooks/useError";
import { getUserConfirmation } from "components/HistoryBlock";

import "normalize.css";
import "fonts/fonts.css";
import "components/Form/Select/index.less";
import GlobalStyle from "styles/index";
import { ToastGlobalStyle } from "@REInventVenturesPteLtd/sui/lib/styles/toastGlobalStyle";
import { LeftSiderWidth } from "components/PortalLayout";

import { getAccountInventoryType } from "service/v1/inventory";
import { getUserCountries } from "service/v1/identity";
import { useBookingCountLoader } from "service/v1/booking";

import {
  PrivateRoutes,
  PublicRutes,
  PrivateMobileRoutes,
  PrivatePCRoutes,
} from "./routes";
import { testMobile } from "utils/os";

import language from "langs/language";
import moment from "moment";
import "moment/locale/zh-cn";
import "moment/locale/zh-tw";
import "moment/locale/zh-hk";
import "moment/locale/ko";

moment.locale(language.momentLang);

const isMobile = testMobile();
const useBlockDropEventPopup = () => {
  useEffect(() => {
    window.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault();
      },
      false
    );
  });
};
// 有需要再开启打印
config({
  log(msg) {
    // try {
    //   console.log(msg);
    // } catch (e) {
    //   console.log(msg + "");
    // }
  },
});

function ProviderWrapper() {
  return (
    <>
      <IntlProvider>
        <AccessTokenProvider>
          <BookingCountsProvider>
            <PortalStyleProvider>
              <PartnersProvider>
                <SpaceProvider>
                  <App />
                </SpaceProvider>
              </PartnersProvider>
            </PortalStyleProvider>
          </BookingCountsProvider>
        </AccessTokenProvider>
      </IntlProvider>
      <GlobalStyle key="global-style" />
      <ToastGlobalStyle leftOffset={LeftSiderWidth} />
    </>
  );
}

function App() {
  const {
    onSetInventoryAuth,
    setCurCountry,
    setUserCountries,
    curCountry,
    accessToken,
  } = useAccessToken();
  const { data } = useBookingCountLoader(curCountry?.code);
  const { setOngoing, setUpcoming, setPending } = useBookingCounts();
  useBlockDropEventPopup();
  useEffect(() => {
    setOngoing(data?.ongoing || 0);
    setUpcoming(data?.upcoming || 0);
    setPending(data?.pending || 0);
  }, [data, setOngoing, setUpcoming, setPending]);
  useEffect(() => {
    if (accessToken) {
      const loadInventoryAuth = async () => {
        try {
          const resp = await getAccountInventoryType();
          if (resp && resp.types) onSetInventoryAuth(resp.types);
        } catch (e) {
          console.error("load inventory auth err: ", e);
          notificationErr(e, "Load inventory auth error.");
        }
      };
      const loadCountry = async () => {
        try {
          const resp = await getUserCountries();
          setCurCountry(resp?.defaultRegion || null);
          const countryList = sortCountry(resp?.countryList);
          setUserCountries(countryList);
        } catch (e) {
          console.error("load country err: ", e);
          notificationErr(e, "Load country error.");
        }
      };
      loadInventoryAuth();
      loadCountry();
    }
  }, [onSetInventoryAuth, setCurCountry, setUserCountries, accessToken]);

  const sortCountry = (countryList: IUserCountries[]) => {
    if (!countryList || !countryList.length) {
      return [];
    }
    countryList.sort((a, b) => a.countryCode.localeCompare(b.countryCode));
    countryList.forEach((country) => {
      if (country?.subdivisions) {
        return country.subdivisions.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }
    });
    return countryList;
  };

  const defaultRoute = accessToken
    ? findFirstValidRoute(accessToken?.authorities || [])
    : `/login`;

  return (
    <>
      <BrowserRouter
        basename={process.env.PUBLIC_URL || "/"}
        getUserConfirmation={getUserConfirmation}
      >
        <Suspense fallback={<div></div>}>
          <Switch>
            {PublicRutes.map((i) => (
              <Route path={i.path} key={i.key} component={i.component} />
            ))}
            <Redirect path="/" exact to={defaultRoute} />
            {PrivatePCRoutes.map((i) => (
              <PrivateRoute
                path={i.path}
                key={i.key}
                exact={i.exact}
                accessLimit={i.accessLimit}
              >
                <i.component />
              </PrivateRoute>
            ))}
            {isMobile &&
              PrivateMobileRoutes.map((i) => (
                <PrivateRoute
                  path={i.path}
                  key={i.key}
                  accessLimit={i.accessLimit}
                  exact={i.exact}
                >
                  <i.component />
                </PrivateRoute>
              ))}
            {PrivateRoutes.map((i) => (
              <PrivateRoute
                path={i.path}
                key={i.key}
                accessLimit={i.accessLimit}
                exact={i.exact}
              >
                <i.component />
              </PrivateRoute>
            ))}
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default ProviderWrapper;

function PrivateRoute({
  children,
  ...rest
}: {
  children: React.ReactNode;
  path: string;
  exact?: boolean;
  accessLimit?: Authorities;
}) {
  const { isLogin, testAuth, accessToken } = useAccessToken();
  const renderComponent = ({ location }: { location: Location }) => {
    if (!isLogin)
      return (
        <Redirect to={{ pathname: "/login", state: { from: location } }} />
      );
    const path = findFirstValidRoute(accessToken?.authorities || []);
    const defaultReidrect = (
      <Redirect to={{ pathname: path, state: { from: location } }} />
    );
    if (rest.accessLimit && !testAuth(rest.accessLimit)) return defaultReidrect;
    return children;
  };
  return <Route {...rest} render={renderComponent} />;
}
