import intl from "langs/intl";
import { Authorities } from "store/accessToken";
import { navigatorText } from "langs/formatText";

export enum SiteNav {
  COUNTRY = "country",
  // dashboard, overview
  DASHBOARD = "overview",
  // space
  SPCAES = "spaces",
  INVENTORY = "inventory",
  SPCAE_CONFIGURATION = "space_configuration",
  // walk-ins
  WALK_INS = "Walk-ins",
  USAGE = "usage",
  SUB_BOOKING = "sub_booking",
  // bookings
  BOOKINGS_USAGE = "bookings_usage",
  BOOKINGS = "bookings",
  // role
  ROLE_MANAGEMENT = "role",
  // admin
  ADMIN_MANAGEMENT = "admin",
  PASSWORD = "password",
  VERIFYCODE = "verify_code",
  // invoice
  INVOICE = "invoice",
  // revenue report
  REVENUE_REPORT = "revenue-report",
  // user Rating
  USER_RATING = "user_rating",
}

type ISiderConfig = {
  key: SiteNav;
  title: string;
  to?: string;
  need?: Authorities[];
  needSome?: Authorities[];
  icon?: string;
  mobile?: boolean;
  subItems?: Array<{
    key: SiteNav;
    title: string;
    to?: string;
    need?: Authorities[];
    needSome?: Authorities[];
    icon?: string;
    mobile?: boolean;
  }>;
};

export const LeftSiderConfig: ISiderConfig[] = [
  {
    key: SiteNav.DASHBOARD,
    title: navigatorText.OverviewText,
    to: "/" + SiteNav.DASHBOARD,
    need: [Authorities.ReadDashboard],
    icon: "IconBook",
    mobile: true,
  },
  {
    key: SiteNav.SPCAES,
    title: navigatorText.SpacesText,
    to: "/" + SiteNav.SPCAES,
    need: [Authorities.ReadSpaces],
    icon: "IconBook",
    mobile: true,
  },
  {
    key: SiteNav.WALK_INS,
    title: navigatorText.WalkInsText,
    needSome: [Authorities.ReadWalkIn],
    icon: "IconBook",
    mobile: true,
    subItems: [
      {
        key: SiteNav.USAGE,
        title: navigatorText.UsageText,
        to: "/" + SiteNav.USAGE,
        need: [Authorities.ReadWalkIn],
        icon: "IconBook",
        mobile: true,
      },
    ],
  },
  {
    key: SiteNav.SUB_BOOKING,
    title: navigatorText.BookingsText,
    needSome: [Authorities.ReadBooking, Authorities.ReadManageBooking],
    icon: "IconBook",
    mobile: true,
    subItems: [
      {
        key: SiteNav.BOOKINGS_USAGE,
        title: navigatorText.UsageText,
        to: "/" + SiteNav.BOOKINGS_USAGE,
        need: [Authorities.ReadBooking],
        icon: "IconBook",
        mobile: true,
      },
      {
        key: SiteNav.BOOKINGS,
        title: navigatorText.ManageBookingsText,
        to: "/" + SiteNav.BOOKINGS,
        need: [Authorities.ReadManageBooking],
        icon: "IconBook",
        mobile: true,
      },
    ],
  },
  {
    key: SiteNav.USER_RATING,
    title: navigatorText.UserRatingText,
    to: "/" + SiteNav.USER_RATING,
    need: [Authorities.ReadUserRatingData],
    icon: "IconBook",
    mobile: true,
  },
  {
    key: SiteNav.REVENUE_REPORT,
    title: intl.formatMessage({ defaultMessage: "Revenue Reports" }),
    to: "/" + SiteNav.REVENUE_REPORT,
    need: [Authorities.ReadRevenueReport],
    icon: "IconBook",
    mobile: true,
  },
  {
    key: SiteNav.INVOICE,
    title: intl.formatMessage({ defaultMessage: "Client Tax Invoices" }),
    to: "/" + SiteNav.INVOICE,
    need: [Authorities.ReadInvoice],
    icon: "IconBook",
    mobile: true,
  },
  {
    key: SiteNav.ROLE_MANAGEMENT,
    title: navigatorText.RoleManagementText,
    to: "/" + SiteNav.ROLE_MANAGEMENT,
    need: [Authorities.ReadRole],
    icon: "IconBook",
    mobile: true,
  },
  {
    key: SiteNav.ADMIN_MANAGEMENT,
    title: navigatorText.AdminManagementText,
    to: "/" + SiteNav.ADMIN_MANAGEMENT,
    need: [Authorities.ReadAdmin],
    icon: "IconBook",
    mobile: true,
  },
];

export const MobileLeftSiderConfig = LeftSiderConfig.filter((item) => {
  return item.mobile;
}).map((item) => {
  if (!item.subItems) return item;
  return {
    ...item,
    subItems: item.subItems.filter((sub) => sub.mobile),
  };
});
