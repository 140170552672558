import styled from "styled-components";
import {
  Neutral4,
  Neutral8,
  Neutral3,
  Primary,
  PrimaryDarker,
  None,
  Neutral6,
} from "styles/colors";

import { ReactComponent as DownloadSVG } from "images/icon-download.svg";

interface ButtonProps {
  disabled?: boolean;
  pressed?: string;
  isPrimary?: boolean;
  defaultBgColor?: string;
}

function getBGColor(props: ButtonProps, hover?: boolean) {
  const { disabled, pressed, isPrimary, defaultBgColor } = props;
  if (disabled && isPrimary) return Neutral3;
  if (disabled) return defaultBgColor || None;
  if (pressed && isPrimary) return PrimaryDarker;
  if (pressed) return defaultBgColor || None;
  if (isPrimary && hover) return PrimaryDarker;
  if (isPrimary) return Primary;
  if (hover) return defaultBgColor || None;
  return defaultBgColor || None;
}

function getBorderColor(props: ButtonProps, hover?: boolean) {
  const { disabled, pressed, isPrimary } = props;
  if (disabled) return Neutral3;
  if (pressed && isPrimary) return PrimaryDarker;
  if (pressed) return Primary;
  if (isPrimary && hover) return PrimaryDarker;
  if (isPrimary) return Primary;
  if (hover) return Primary;
  return Neutral4;
}

function getFontColor(props: ButtonProps, hover?: boolean) {
  const { disabled, isPrimary } = props;
  if (disabled) return Neutral6;
  if (isPrimary) return "#FFF";
  if (hover) return Primary;
  return Neutral8;
}

export const ButtonWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 16.5px;
  height: 32px;
  min-width: 72px;
  line-height: 16px;
  border-radius: 2px;
  border: 1px solid ${Neutral4};
  display: flex;
  flex: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: ${(p: ButtonProps) => getFontColor(p)};
  background-color: ${(p: ButtonProps) => getBGColor(p)};
  border-color: ${(p: ButtonProps) => getBorderColor(p)};
  cursor: ${(p: ButtonProps) => (p.disabled ? "not-allowed" : "pointer")};
  transition: all 0.2s ease;

  svg {
    fill: ${(p: ButtonProps) => getFontColor(p)};
    transition: all 0.2s ease;
  }

  &:hover {
    color: ${(p: ButtonProps) => getFontColor(p, true)};
    background-color: ${(p: ButtonProps) => getBGColor(p, true)};
    border-color: ${(p: ButtonProps) => getBorderColor(p, true)};

    svg {
      fill: ${(p: ButtonProps) => getFontColor(p, true)};
    }
  }
`;

export const IconDownload = styled(DownloadSVG)`
  width: 16px;
  height: 16px;
  fill: ${Neutral8};
  transition: fill 0.2s ease;
`;
