import React, { memo } from "react";
import { message as Message } from "antd";

import * as S from "./styles.notification";

type TSuccessNotification = {
  message: string;
  duration?: number;
};

const style: React.CSSProperties = {
  // width: "auto", // calc(100vw - 208px - 32px * 2)
  // maxWidth: "calc(100vw - 208px - 32px * 2)",
  // padding: "11px 18px",
  // marginRight: 10,
};

type NotificationType = "info" | "success" | "error" | "warn";

function openNotification(
  type: NotificationType = "info",
  message = "",
  duration = 3
) {
  let icon = <S.IconInfo />;
  if (type === "success") icon = <S.IconSuccess />;
  if (type === "error") icon = <S.IconError />;
  if (type === "warn") icon = <S.IconWarn />;
  Message.open({
    className: `portal-notification ${type} commonMessage`,
    content: <MessageItem message={message} icon={icon} />,
    style,
    duration: duration || 3,
    type: "info",
    icon: <div />,
  });
}

const Notification = {
  open: Message.open,
  success: ({ message, duration }: TSuccessNotification) =>
    openNotification("success", message, duration),
  error: ({ message, duration }: TSuccessNotification) =>
    openNotification("error", message, duration),
  warn: ({ message, duration }: TSuccessNotification) =>
    openNotification("warn", message, duration),
  info: ({ message, duration }: TSuccessNotification) =>
    openNotification("info", message, duration),
};

export default Notification;

interface MessageItemProps {
  message: string;
  icon?: React.ReactNode;
}

const MessageItem = memo(({ message, icon }: MessageItemProps) => (
  <S.Wrapper>
    {icon}
    <S.Message>{message}</S.Message>
  </S.Wrapper>
));
