import { lazy, ExoticComponent } from "react";

import {
  SiteNav,
  LeftSiderConfig,
  MobileLeftSiderConfig,
} from "constants/navigators";
import { Authorities } from "store/accessToken";
import { testMobile } from "utils/os";
import Home from "pages/Home";

const Login = lazy(() => import("pages/Login"));
const NotAllowed403 = lazy(() => import("pages/403"));
const SpaceForm = lazy(() => import("pages/Spaces/SpaceForm"));
const InventoryForm = lazy(() => import("pages/Spaces/InventoryForm"));
const Password = lazy(() => import("pages/Password"));
const VerifyCode = lazy(() => import("pages/VerifyCode"));
const UserRating = lazy(() => import("pages/UserRating"));

type AppComponent = () => JSX.Element;

export type AppRoute = {
  path: string;
  key: string;
  component: ExoticComponent | AppComponent;
  exact?: boolean;
  accessLimit?: Authorities;
};

export const PublicRutes: AppRoute[] = [
  { path: "/login", key: "login", component: Login },
  {
    path: `/403`,
    key: `/403`,
    component: NotAllowed403,
  },
  {
    path: `/${SiteNav.PASSWORD}`,
    key: SiteNav.PASSWORD,
    component: Password,
  },
  {
    path: `/${SiteNav.VERIFYCODE}`,
    key: SiteNav.VERIFYCODE,
    component: VerifyCode,
  },
];

// both pc & mobile
export const PrivateRoutes: AppRoute[] = [];

export const PrivatePCRoutes: AppRoute[] = [
  // dashboard
  {
    path: `/${SiteNav.DASHBOARD}`,
    key: SiteNav.DASHBOARD,
    component: Home,
    accessLimit: Authorities.ReadDashboard,
  },
  // spaces
  {
    path: `/${SiteNav.SPCAES}`,
    key: SiteNav.SPCAES,
    component: Home,
    exact: true,
    accessLimit: Authorities.ReadSpaces,
  },
  {
    path: `/${SiteNav.SPCAES}/add`,
    key: `/${SiteNav.SPCAES}/add`,
    component: SpaceForm,
    accessLimit: Authorities.AddSpace,
  },
  {
    path: `/${SiteNav.SPCAES}/edit/:id?`,
    key: `/${SiteNav.SPCAES}/edit/:id?`,
    component: SpaceForm,
    accessLimit: Authorities.EditSpace,
  },
  {
    path: `/${SiteNav.SPCAES}/duplicate/:id?`,
    key: `/${SiteNav.SPCAES}/duplicate/:id?`,
    component: SpaceForm,
    accessLimit: Authorities.ReadSpaces,
  },
  {
    path: `/${SiteNav.SPCAES}/view/:id?`,
    key: `/${SiteNav.SPCAES}/view/:id?`,
    component: SpaceForm,
    accessLimit: Authorities.ReadSpaces,
  },
  {
    path: `/${SiteNav.INVENTORY}/add/:spaceID`,
    key: `/${SiteNav.INVENTORY}/add/:spaceID`,
    component: InventoryForm,
    accessLimit: Authorities.AddInventory,
  },
  {
    path: `/${SiteNav.INVENTORY}/edit/:id?`,
    key: `/${SiteNav.INVENTORY}/edit/:id?`,
    component: InventoryForm,
    accessLimit: Authorities.EditInventory,
  },
  {
    path: `/${SiteNav.INVENTORY}/duplicate/:id?`,
    key: `/${SiteNav.INVENTORY}/duplicate/:id?`,
    component: InventoryForm,
    accessLimit: Authorities.AddInventory,
  },
  {
    path: `/${SiteNav.INVENTORY}/view/:id?`,
    key: `/${SiteNav.INVENTORY}/view/:id?`,
    component: InventoryForm,
    accessLimit: Authorities.ReadSpaces,
  },
  // walk-ins
  {
    path: `/${SiteNav.USAGE}`,
    key: SiteNav.USAGE,
    component: Home,
    accessLimit: Authorities.ReadWalkIn,
  },
  // bookings
  {
    path: `/${SiteNav.BOOKINGS_USAGE}/:type?`,
    key: SiteNav.BOOKINGS_USAGE,
    component: Home,
    accessLimit: Authorities.ReadBooking,
  },
  {
    path: `/${SiteNav.BOOKINGS}`,
    key: SiteNav.BOOKINGS,
    component: Home,
    accessLimit: Authorities.ReadManageBooking,
  },
  {
    path: `/${SiteNav.USER_RATING}`,
    key: SiteNav.USER_RATING,
    component: UserRating,
    accessLimit: Authorities.ReadUserRatingData,
  },
  // role
  {
    path: `/${SiteNav.ROLE_MANAGEMENT}`,
    key: SiteNav.ROLE_MANAGEMENT,
    component: Home,
    accessLimit: Authorities.ReadRole,
  },
  // admin
  {
    path: `/${SiteNav.ADMIN_MANAGEMENT}`,
    key: SiteNav.ADMIN_MANAGEMENT,
    component: Home,
    accessLimit: Authorities.ReadAdmin,
  },
  // invoice
  {
    path: `/${SiteNav.INVOICE}`,
    key: SiteNav.INVOICE,
    component: Home,
    accessLimit: Authorities.ReadInvoice,
  },
  // revenue report
  {
    path: `/${SiteNav.REVENUE_REPORT}`,
    key: SiteNav.REVENUE_REPORT,
    component: Home,
    accessLimit: Authorities.ReadRevenueReport,
  },
];

export const PrivateMobileRoutes: AppRoute[] = [...PrivatePCRoutes];

// find entries
export const findFirstValidRoute = (authorities: string[]) => {
  const isMobile = testMobile();
  const entryList = isMobile ? MobileLeftSiderConfig : LeftSiderConfig;
  let find = "";

  entryList.forEach((item) => {
    if (find) return;

    // need some
    if (
      item.needSome?.length &&
      !item.needSome.some((p) => {
        return authorities.includes(p);
      })
    ) {
      return;
    }

    // need
    if (
      item.need?.length &&
      !item.need.every((p) => {
        return authorities.includes(p);
      })
    ) {
      return;
    }

    // to
    if (item.to) {
      find = item.to;
    }

    if (!item.to && item.subItems) {
      item.subItems.forEach((child) => {
        if (find) return;

        // need some
        if (
          child.needSome?.length &&
          !child.needSome.some((p) => {
            return authorities.includes(p);
          })
        ) {
          return;
        }

        // need
        if (
          child.need?.length &&
          !child.need.every((p) => {
            return authorities.includes(p);
          })
        ) {
          return;
        }

        find = child.to || "";
      });
    }
  });

  return find || "/403";
};
